import { Component, Input } from '@angular/core';

@Component({
	selector: 'c-menu-iterator-sel',
	templateUrl:'menu-iterator-sel.component.html'
})

export class MenuIteratorSelComp{

	@Input() menus : any = false;

	constructor(){}

	getMenus(menus){
		/*
		var str = [];

		if(menus){
			for(var i in menus){
				str += "<div>"+menus[i].nombre+"</div>";

				if(menus[i].hijos){
					str += this.getMenus(menus[i].hijos);
				}
			}
		}

		console.log(str);

		return str;
		*/
		return "";
	}

	/*recurMenu(menu){
		var str = "";

		str += "<option>"+menu.nombre+"</option>";


		return str;
	}*/
}
