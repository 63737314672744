import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'imagen-modal',
	templateUrl:'imagen-modal.component.html'
})

export class ImagenModalComp{
	
	@Input() imagen : any = false;
	@Input() activo : boolean = false;

	constructor(){}

	getImagen(img){

		var strImg = 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(img.nombre);
		return strImg;
	}

}