import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogComp } from '../dialog/dialog.component';
import { DialogServ } from '../../services/dialog.service';
import { Ajax } from '../../services/ajax.service';
import { GaleriaServ } from '../../services/galeria.service';


@Component({
	selector: 'img-upload',
	templateUrl:'img-upload.component.html'
})

export class ImgUploadComp	 {

	@Input() id:any = false;

	file: any;
	imagen: any;

	constructor(
		public galeriaServ : GaleriaServ,
		private ajax:Ajax
		){}

	getImg(){


		return this.imagen.data
	}

	onSelectFile(event) { // called each time file input changes
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();


			this.file = event.target.files[0];
			reader.readAsDataURL(event.target.files[0]); // read file as data url

			var obj = this;

			reader.onload = (event) => {

				this.imagen = {
					data:event.target['result'],
					nombre:this.file.name
				}

			}
		}
	}

	enviar(){
		this.ajax.post('imagen/upload',this.imagen).subscribe(
			res => {
				var resp:any = res;
				if(resp.resp){
					this.galeriaServ.loadImgs();
					this.imagen = false;
				}
			},
			error => console.error('Error: '),() => console.log(''));
	}
}
