import { Component, OnInit } from '@angular/core';

import { BodyServ } from '../../body/body.service';
import { Header } from '../../header/header.class';

@Component({
	selector: 'c-carreras',
	templateUrl:'carreras.component.html'
})

export class CarrerasComp implements OnInit {
	constructor(
		public bodyServ : BodyServ
		){}

	ngOnInit() {

		this.bodyServ.header.titulo = "Carreras";
		this.bodyServ.header.cod = '1';
		this.bodyServ.isPanel = false;
	}
}
