import { Component, OnInit, Input } from '@angular/core';

import { DialogServ } from '../../services/dialog.service';

@Component({
	selector:'res-dialog',
	templateUrl: './dialog.component.html'
})

export class DialogComp implements OnInit {
	@Input() id : string; 
	@Input() classTemp : string ='unaclasedeprueba';

	public activo : boolean = false;

	constructor (
		public dService:DialogServ
		) {}

	ngOnInit() {
		this.dService.add(this);
	}

	toggle(){
		this.activo = !this.activo;
	}

	open(){
		this.activo = true;
	}

	close(){
		this.activo = false;
	}

	getClasses(){
		var c : string = this.activo?"dialog-active":"";

		c = c+' '+this.classTemp;
	
		return c;
	}
}
