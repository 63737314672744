import { Component, Input } from '@angular/core';
// import { BodyServ } from '../../body/body.service';

@Component({
	selector:'breadcrumb-iterator',
	templateUrl: './breadcrumb-iterator.component.html'
})

export class BreadcrumbIterator{

  @Input() pagina;

	constructor (
  ) {}

	ngOnInit() {
  }

	getUrl(pag){

		var url = ''

		if(pag.padre != false){
			if(pag.padre.id != 0){
				url = this.getUrl(pag.padre);
			}
		}

			url += '/'+pag.url;

		return url;
	}
}
