import { Component,OnInit, TemplateRef, HostListener, Input } from '@angular/core';
import { Item } from './item.class';

@Component({
	selector: 'c-item',
	templateUrl:'item.component.html',
   	styleUrls: ['./item.component.css']
})

export class ItemComp implements OnInit {

	@Input() item;
	@Input() colums;


	constructor(){}

	ngOnInit(){
	}

	getWidth(){
		return 100/this.colums.getCol();
	}

	getLeft(){
		var l = this.item.posicion*(100/this.colums.getCol());
		return l;
	}

	getClasses(){
		var c = []//'';
		if(this.item.activo){
			//c += 'active';
			c.push('active');
		}

		if(this.item.itemOutLeft){
			// c += 'item-out-left';
			c.push('item-out-left');
		}

		return c;
	}
}
