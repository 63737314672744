import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Ajax } from '../../services/ajax.service';

@Component({
  selector: 'fm-carrera',
  template: `
  <div class="input-group text-left" [formGroup]="form">
    <span class="input-group-addon" id="basic-addon1">{{field.etiqueta}}</span>
    <select placeholder="Seleccionar" class="form-control" [id]="field.cod" [formControlName]="field.cod">
      <option *ngFor="let c of carreras" [value]="c.id">{{c.titulo}}</option>
    </select>
    </div>
  `
})

export class FmCarreraComponent {
  @Input() field:any = {};

  //1 = BSAS - 2 = Madryn

  @Input() form:FormGroup;

  public carreras;

  constructor(
    public ajax:Ajax
  ){}


  ngOnInit() {

    this.ajax.get('pagina/hijas/81/'+encodeURI('titulo'))
    .subscribe(
      res => {
        var resp:any = res;
        this.carreras = resp.resp.data;

        for(var i in this.carreras){
          if(this.carreras[i].url == "/carreras/despachante-de-aduana"){

            this.carreras.splice(i,1);
          }
        }

      },
      error => console.error('Error: '),() => console.log('Hubo un error'));

    }


    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
  }
