

/** /// <reference path="../../../../../node_modules/@types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />  */




import { Component, ViewChild, OnInit, AfterContentInit } from '@angular/core';

@Component({
	selector: 'c-map',
	templateUrl:'map.component.html'
})
///////https://itnext.io/using-bing-maps-in-blazor-with-jsinterop-and-typescript-90e888e0e2fd
export class MapComp implements AfterContentInit {
	@ViewChild('mapaFAECC', {static: true}) mapaFAECC;

	public sedeAct = 1;

	public myMap;

	constructor(
		){}

	ngAfterContentInit(){

		// this.iniMap();

	}

/*
	iniMap(){
		this.myMap = new Microsoft.Maps.Map(this.mapaFAECC.nativeElement, {
			center: new Microsoft.Maps.Location(-34.60948, -58.3787),
			zoom: 15});

			var center = this.myMap.getCenter();
			var infobox = new Microsoft.Maps.Infobox(center, { title: 'La Fundación Sede Central',
			description: 'Encontranos en Hipólito Yrigoyen 874, CABA, Buenos Aires, Argentina' });
			infobox.setMap(this.myMap);

			infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(-42.779018, -65.024279),{
				title: 'La Fundación Sede Madryn',
				description: 'Encontranos en Roberts 61, Puerto Madryn, Chubut, Argentina' });
				infobox.setMap(this.myMap);
		}

	mapGoBsas(){
		this.sedeAct=1;
		this.myMap.setView({
			center: new Microsoft.Maps.Location(-34.60948, -58.3787),
			zoom:15
		});

	}

	mapGoMadryn(){
		this.sedeAct=2;
		this.myMap.setView({
			center: new Microsoft.Maps.Location(-42.779018, -65.024279),
			zoom:15
		});
	}
*/
}
