import { Component,Injectable } from '@angular/core';
import { Ajax } from './ajax.service';

@Injectable()

export class GaleriaServ {

	galerias : any;
	imgs:any;


	constructor(
		private ajax: Ajax
		) { }

	loadImgs(){
		this.ajax.get('imagen/get').subscribe(
			res => {
				
				var resp:any = res;

				if(resp.resp){
					this.imgs = resp.imgs;
				}

			},
			error => console.error('Error: '),() => console.log(''));
	}


	loadGalerias(){
		this.ajax.get('galeria/get').subscribe(
			res => {
				
				var resp:any = res;

				if(resp.resp){
					this.galerias = resp.resp.data;
				}

			},
			error => console.error('Error: '),() => console.log(''));
	}

	nuevo(galeria){
		this.ajax.post('galeria/nuevo',galeria).subscribe(
			res => {
				
				var resp:any = res;
				
				console.log(resp);

				if(resp.tipo=='confirmo' && resp.resp.resp=="true"){
					//this.router.navigate(["/panel/menus"]);
				}

				//else if(resp.tipo=='error'){
					// 	var errs = resp.resp.error.errores;
					// 	this.repoErr(errs);

					// 	console.log(errs);

					// }
				},
				error => console.error('Error: '),() => console.log(''));
	}

	editar(galeria){
		
		this.ajax.post('galeria/editar',galeria).subscribe(
			res => {
				
				var resp:any = res;
				
				console.log(resp);

				if(resp.tipo=='confirmo' && resp.resp.resp=="true"){
					//this.router.navigate(["/panel/menus"]);
				}

				//else if(resp.tipo=='error'){
					// 	var errs = resp.resp.error.errores;
					// 	this.repoErr(errs);

					// 	console.log(errs);

					// }
				},
				error => console.error('Error: '),() => console.log(''));
	}
}