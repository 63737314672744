import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
      <div [formGroup]="form">
        <div [formGroupName]="field.cod" >

          <label class="color-white">{{field.etiqueta}}</label>
          <div class='row'>
            <div *ngFor="let opt of field.opciones" class="form-check form-check col-sm-12 col-md-6">
              <label class="color-white">
                <input [formControlName]="opt.cod" class="form-check-input" type="checkbox" [value]="opt.cod" />
                 {{opt.etiqueta}}
              </label>
            </div>
          </div>
        </div>
      </div>
    `

})
export class CheckBoxComponent implements OnInit{
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    // get isValid() { return true; }
    // get isDirty() { return true; }
    ngOnInit() {
      // console.log(this.field);
    }
}
