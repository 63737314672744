import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { BodyServ } from '../body/body.service';
import { Header } from '../header/header.class';
import { Ajax } from '../services/ajax.service';

@Component({
  selector: 'pg-pasantias-eventos',
  templateUrl:'pasantias-eventos.component.html'
})


export class PasantiasEventosComp implements OnInit{

  @ViewChild('descTop', {static: false}) descTop: ElementRef;

  public section:any = false;
  public novedades : any = false;
  // public cantPag : any = 0;
  // public max : number = 6;
  // public offsetAct : any = 0;



  //public imagenes:any; -> novedades
  public novsCant = 0;
  public novXPag = 10;
  public pags : any = false;
  public pagAct = 0;
  public firstLoad = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ajax : Ajax,
    public bodyServ : BodyServ
  ){}

  ngOnInit() {

    this.bodyServ.setSection(false);


    //NOVEDADES

    this.ajax.get('pagina/get/carreras/organizacion-de-eventos/pasantias-de-la-carrera')
		.subscribe(
			res => {

  			var resp:any = res;

        if(resp.tipo=='confirmo'){
          var sectAux = resp.resp.data;

          var articulos = {};

          for(var i in sectAux.articulos){
            articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
          }

          this.section = sectAux;
          this.section.articulos = articulos;
          this.bodyServ.setSection(this.section);
        }
			},
			error => console.error('Error: '),() => console.log(''));

    this.loadNovs();

		this.bodyServ.header.titulo = "Home";
		this.bodyServ.header.cod = '1';
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);
  }


  pagSiguiente(){
    this.pagAct++;
    this.loadNovs();
  }

  goPag(p){
    this.pagAct = p.num-1;
    this.loadNovs();
  }

  loadNovs(){
    var offset = this.pagAct*this.novXPag;
    this.ajax.get('pagina/hijas/142/'+encodeURI('titulo:descripcion_breve:imagen_chica')+'/'+this.novXPag+'/'+offset).subscribe(
      res => {

        var resp:any = res;


        if(resp.resp){

          for(var j in resp.resp.data.paginas){

            var articulos = {};

            for(var i in resp.resp.data.paginas[j].articulos){
              articulos[resp.resp.data.paginas[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data.paginas[j].articulos[i];
            }

            resp.resp.data.paginas[j].articulos = articulos;
          }

          this.novedades = resp.resp.data.paginas;
          this.novsCant = resp.resp.data.cantPag;


          if(!this.firstLoad){
        		var el = this['descTop'].nativeElement.getBoundingClientRect();

        		var offsetTopIni = el.top + window.pageYOffset - 50;

        		window.scrollTo({
        			top: offsetTopIni,
        			left: 0,
        			behavior: 'smooth'
        		});
          }

          this.firstLoad = false;


          this.loadPags();
        }
      },
      error => console.error('Error: '),() => console.log(''));
  }

  loadPags(){
    var cantPags = Math.ceil(this.novsCant/this.novXPag);

    if(cantPags==1){
      this.pags = false;
    }else{
      this.pags = [];

      var offset = this.pagAct*this.novXPag;

      // var maxPags = (cantPags>6;);
      var lastPag = -1;
      for(var i=0;i<cantPags;i++){

        //Tienen que listarse:
        //  - La primera y última
        //  - 2 antes y 2 despues de la actual
        var cont = true;

        if(
          (i==0  ||
          i==(cantPags-1)) ||
          ((this.pagAct-i)<=2 &&
          (this.pagAct-i)>=(-2))
        ){
          cont = false;
        }

        if(cont){
          continue;
        }

        var separator = false;
        if(lastPag != i-1){
          separator = true;

          this.pags.push({
            num: false,
            separator: separator
          });

        }
        lastPag = i;

        this.pags.push({
          num: i+1,
          separator: false
        });

      }

    }
  }





  //
  // getPasantias(){
  //
  //   var offsetAbs = this.offsetAct*this.max;
  //
  //   this.ajax.get('pagina/hijas/142/'+encodeURI('titulo:descripcion_breve:imagen_chica')+'/'+this.max+'/'+offsetAbs).subscribe(
  //     res => {
  //       var resp:any = res;
  //       console.log(resp);
  //       if(resp.resp.resp){
  //         // this.novedades = resp.resp.data;
  //
  //         for(var j in resp.resp.data.paginas){
  //
  //           var articulos = {};
  //
  //           for(var i in resp.resp.data.paginas[j].articulos){
  //             articulos[resp.resp.data.paginas[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data.paginas[j].articulos[i];
  //           }
  //
  //           resp.resp.data.paginas[j].articulos = articulos;
  //         }
  //
  //         this.novedades = resp.resp.data.paginas;
  //         this.cantPag = resp.resp.data.cantPag;
  //         console.log(this.novedades);
  //       }
  //     },
  //   error => console.error('Error: '),
  //   () => console.log('consulta completa'));
  // }
  //
  // siguiente(){
  //   if(this.offsetAct < ((this.cantPag/this.max)-1)){
  //     this.offsetAct++;
  //   }else{
  //     this.offsetAct=0;
  //   }
  //   this.getPasantias();
  //
	// 	var el = this['descTop'].nativeElement.getBoundingClientRect();
  //
	// 	var offsetTopIni = el.top + window.pageYOffset - 300;
  //
	// 	window.scrollTo({
	// 		top: offsetTopIni,
	// 		left: 0,
	// 		behavior: 'smooth'
	// 	});
  // }

  getImagen(art){
    console.log('http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre));

    return 'https://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
  }

}
