import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { BodyServ } from '../body/body.service';
import { Header } from '../header/header.class';
import { Ajax } from '../services/ajax.service';

@Component({
  selector: 'pg-novedades',
  templateUrl:'novedades.component.html'
})


export class NovedadesComp implements OnInit{

  @ViewChild('descTop', {static: false}) descTop: ElementRef;

  public section:any = false;
  public novedades : any = false;
  public cantPag : any = 0;
  public max : number = 6;
  public offsetAct : any = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ajax : Ajax,
    public bodyServ : BodyServ
  ){}

  ngOnInit() {

    this.bodyServ.setSection(false);


    //NOVEDADES

    this.ajax.get('pagina/get/institucional/novedades')
		.subscribe(
			res => {


			var resp:any = res;
			console.log(resp);

    if(resp.tipo=='confirmo'){
        var sectAux = resp.resp.data;

        var articulos = {};

        for(var i in sectAux.articulos){
          articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
        }

        this.section = sectAux;
        this.section.articulos = articulos;
        this.bodyServ.setSection(this.section);
      }
			},
			error => console.error('Error: '),() => console.log('Hubo un error'));

    this.getNovedades();

		this.bodyServ.header.titulo = "Home";
		this.bodyServ.header.cod = '1';
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);
  }

  getNovedades(){

    var offsetAbs = this.offsetAct*this.max;

    this.ajax.get('pagina/hijas/102/'+encodeURI('titulo:descripcion_breve:imagen')+'/'+this.max+'/'+offsetAbs).subscribe(
      res => {
        var resp:any = res;
        console.log(resp);
        if(resp.resp.resp){
          // this.novedades = resp.resp.data;

          for(var j in resp.resp.data.paginas){

            var articulos = {};

            for(var i in resp.resp.data.paginas[j].articulos){
              articulos[resp.resp.data.paginas[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data.paginas[j].articulos[i];
            }

            resp.resp.data.paginas[j].articulos = articulos;
          }

          this.novedades = resp.resp.data.paginas;
          this.cantPag = resp.resp.data.cantPag;
          console.log(this.novedades);
        }
      },
    error => console.error('Error: '),
    () => console.log('consulta completa'));
  }

  siguiente(){
    if(this.offsetAct < ((this.cantPag/this.max)-1)){
      this.offsetAct++;
    }else{
      this.offsetAct=0;
    }
    this.getNovedades();

		var el = this['descTop'].nativeElement.getBoundingClientRect();

		var offsetTopIni = el.top + window.pageYOffset - 300;

		window.scrollTo({
			top: offsetTopIni,
			left: 0,
			behavior: 'smooth'
		});
  }

  getImagen(art){
    return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+art.imagen.nombre;
  }

}
