import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ResModule } from '../res/res.module';

import { PanelRoutingModule } from './panel-routing.module';
import { PanelComp } from './panel.component';
import { PanelFormularioComp } from './panel-formulario/panel-formulario.component';
import { PanelMultimediaComp } from './panel-multimedia/panel-multimedia.component';

import { LoginComp } from './modelos/login/login.component';

import { MensajeListComp } from './modelos/mensaje/mensaje-list/mensaje-list.component';

import { MenuListComp } from './modelos/menu/menu-list/menu-list.component';
import { MenuIteratorComp } from './modelos/menu/menu-list/menu-iterator/menu-iterator.component';
import { MenuIteratorSelComp } from './modelos/menu/menu-iterator-sel/menu-iterator-sel.component';
import { MenuCrearComp } from './modelos/menu/menu-crear/menu-crear.component';
import { MenuEditarComp } from './modelos/menu/menu-editar/menu-editar.component';

import { PaginaComp } from './modelos/pagina/pagina.component';
import { PaginaEditarComp } from './modelos/pagina/pagina-editar/pagina-editar.component';
import { PaginaSearch } from './modelos/pagina-search/pagina-search.component';
import { PaginaListComp } from './modelos/pagina-list/pagina-list.component';
import { PaginaIteratorComp } from './modelos/pagina-list/pagina-iterator/pagina-iterator.component';
import { PaginaSelectComp } from './modelos/pagina-list/pagina-select/pagina-select.component';
import { PaginaIteratorSelectComp } from './modelos/pagina-list/pagina-select/pagina-iterator-select/pagina-iterator-select.component';
import { PagArtsListComp } from './modelos/pagina/pag-arts-list/pag-arts-list.component';

import { PagArtHtmlComp } from './modelos/pagina/pag-art-html/pag-art-html.component';
import { PagArtImgComp } from './modelos/pagina/pag-art-img/pag-art-img.component';
import { PagArtSliderComp } from './modelos/pagina/pag-art-slider/pag-art-slider.component';
import { PagArtGaleriaComp } from './modelos/pagina/pag-art-galeria/pag-art-galeria.component';
import { PagArtCarreraComp } from './modelos/pagina/pag-art-carrera/pag-art-carrera.component';
import { PagArtFormComp } from './modelos/pagina/pag-art-form/pag-art-form.component';

import { GaleriaComp } from './modelos/galeria/galeria.component';
import { GaleriaListComp } from './modelos/galeria/galeria-list/galeria-list.component';
import { GaleriaEditComp } from './modelos/galeria/galeria-edit/galeria-edit.component';
import { GaleriaNewComp } from './modelos/galeria/galeria-new/galeria-new.component';

@NgModule({
  imports: [
    CommonModule,
    PanelRoutingModule,
    FormsModule,
    ResModule
  ],
  declarations:[
    //Aca van todos los componentes que quiero importar en otros modulos.
    PanelComp,
    PanelFormularioComp,
    PanelMultimediaComp,
    LoginComp,
    MensajeListComp,
    MenuListComp,
    MenuIteratorComp,
    MenuIteratorSelComp,
    MenuCrearComp,
    MenuEditarComp,
    PaginaComp,
    PaginaEditarComp,
    PaginaSearch,
    PaginaListComp,
    PaginaIteratorComp,
    PaginaSelectComp,
    PaginaIteratorSelectComp,
    PagArtsListComp,
    PagArtHtmlComp,
    PagArtImgComp,
    PagArtSliderComp,
    PagArtGaleriaComp,
    PagArtCarreraComp,
    PagArtFormComp,
    GaleriaComp,
    GaleriaListComp,
    GaleriaEditComp,
    GaleriaNewComp
  ]
})
export class PanelModule { }
