import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { GaleriaServ } from '../../../../services/galeria.service';
import { DialogServ } from '../../../../services/dialog.service';

@Component({
	selector: 'galeria-new',
	templateUrl:'galeria-new.component.html'
})

export class GaleriaNewComp implements OnInit{

	public galeria = {
		imgs:[],
		nombre:''
	};

    @Output() cerrarNueva = new EventEmitter();

	public pagSelect = 0;
	public pagLength = 6;
	public dialogId = 'galeriaNewDialog';

	constructor(
		public dialogServ : DialogServ,
		public galeriaServ:GaleriaServ
		){}

	ngOnInit(){
		this.galeriaServ.loadImgs();
	}


	openDialog() {
		this.dialogServ.open(this.dialogId);
	}

	closeDialog() {
		this.dialogServ.close(this.dialogId);
	}

	imgSeleccionada(img){

		for(var i in this.galeria.imgs){

			if(this.galeria.imgs[i].imagen.id==img.id){
				return true;
			}
		}

		return false;
	}

	imgChange(img){

		var esta = false;

		if(!this.galeria.imgs){
			this.galeria.imgs = [];
		}

		for(var i in this.galeria.imgs){

			if(this.galeria.imgs[i].imagen.id==img.id){
				this.galeria.imgs.splice(Number(i),1);
				esta = true;
			}
		}

		if(!esta){
			this.galeria.imgs.push({imagen:img});
		}
	}

	arrayNum(n: number): any[] {
		var newArr = [];
		var i = 1;

    	while(n>=i){
    		newArr.push(i);
    		i++;
    	}
    	return newArr;
  	}

  	guardar(){
		this.galeriaServ.nuevo(this.galeria);
  	}

  	cerrar(){
        this.cerrarNueva.emit(true);
  	}
}
