export class Colums{

	private cols = {
		xs:0,
		sm:0,
		md:0,
		lg:0,
		xl:0,
		min:0,
		max:0
	}


//Xs <			576px
//Small ≥		576px
//Medium ≥		768px
//Large ≥		992px
//Extra large ≥	1200px

	constructor(){}

	setXsCols(numCols){
		this.cols.xs = numCols;
	}

	setSmCols(numCols){
		this.cols.sm = numCols;
	}

	setMdCols(numCols){
		this.cols.md = numCols;
	}

	setLgCols(numCols){
		this.cols.lg = numCols;
	}

	setXlCols(numCols){
		this.cols.xl = numCols;
	}

	setMaxCols(numCols){
		this.cols.max = numCols;
	}

	getCol():any{
		var realCol = this.getRealCol();
		if(realCol>0){
			if(this.cols.max>0){
				return (this.cols.max>=realCol ? realCol:this.cols.max);
			}

			return realCol;
		}

		return 1;
	}


	getRealCol(){
		//tengo que devolver siempre el tamaño mas chico.

		if(window.innerWidth>=1200 && this.cols.xl > 0){
			return this.cols.xl;
		}else if(window.innerWidth>=992 && this.cols.lg > 0) {
			return this.cols.lg;
		}else if(window.innerWidth>=768 && this.cols.md > 0) {
			return this.cols.md;
		}else if(window.innerWidth>=576 && this.cols.sm > 0) {
			return this.cols.sm;
		}else if(window.innerWidth<576 && this.cols.xs > 0) {
			return this.cols.xs;
		}

		return false;
	}
}
