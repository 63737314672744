import { Component, Input } from '@angular/core';

@Component({
	selector: 'pag-art-img',
	templateUrl:'pag-art-img.component.html'
})

export class PagArtImgComp{

	@Input() artPag:any = false;
	@Input() artCat:any = false;

	constructor(){}

	getImgSel(e){
		this.artPag.imagen = e;
	}

	getTest(){
		return this.artPag.imagen;
	}

}