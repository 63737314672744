import { Component, Input, OnInit } from '@angular/core';
import { Slider } from '../slider/slider-test/slider.class';
import { Colums } from '../res/colums.class';
import { DialogServ } from '../services/dialog.service';
import { GaleriaModalServ } from '../services/galeria-modal.service';


@Component({
	selector: 'c-art-galeria',
	templateUrl:'art-galeria.component.html'
})

export class ArtGaleriaComp implements OnInit {
	
	@Input() articulo : any = false;

	public sliderImg;
	public itemsImg;


	constructor(
		public dialog : DialogServ,
		public galeriaModalServ:GaleriaModalServ
	){}

	ngOnInit(){

		var c = new Colums();

		c.setXsCols(1);
		c.setMdCols(4);

		this.sliderImg = new Slider(c,200,this.articulo.galeria.imgs,this.itemsImg,6000);

		this.itemsImg = this.sliderImg.contenidosToItems(this.articulo.galeria.imgs);
		this.galeriaModalServ.galeria = this.articulo.galeria;
		this.galeriaModalServ.imgAct = this.articulo.galeria.imgs[0];

	}

	getArt(){
		
		return this.articulo;
	}

	getImagen(item){

		var strImg = 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(item.contenido.imagen.nombre);
		return strImg;
	}

	openDialog(img) {
		this.galeriaModalServ.updateImgAct(img);
		this.galeriaModalServ.open();
	}

	// closeDialog() {
	// }
}