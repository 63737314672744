import { Component } from '@angular/core';

import { BodyServ } from '../body/body.service';

//import { Header } from './header.class';

@Component({
	selector: 'c-footer',
	templateUrl:'footer.component.html'
})

export class FooterComp{
	footer : any = 'false';

	constructor(
		public bodyServ : BodyServ
	){}

	getFooter(){
		this.footer = this.bodyServ.getFooter();
		return this.footer;
	}

}
