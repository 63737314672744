import { Item } from './item/item.class';
import { Colums } from '../../res/colums.class';

export class Slider{

	public colums:Colums;
	public height = 300;
	public contenidos;
	public items;
	public transitionTime;

	constructor(colums,height,contenidos,items,transitionTime){


		if(colums.getCol()>contenidos.length-1){
			colums.setMaxCols(
				(contenidos.length-1<=0 ? 1 : contenidos.length-1)
				);
		}

		this.colums = colums;
		this.height = height;
		this.contenidos = contenidos;
		this.items = items;
		this.transitionTime = transitionTime;
	}

	contenidosToItems(contenidos){

		this.items = [];
		var id = (contenidos.length-1<=0 ? 0 : -1);

		for(var i in contenidos){

			this.items.push(new Item(
				(id>-1 && id<this.colums.getCol() ? true : false),
				id,contenidos[i]));

			id++;
		}

		return this.items;
	}

	siguiente(){

		if(this.items.length>1){

			for(var i in this.items){
				if( this.items[i].posicion ==  -1){
					this.items[i].posicion = this.items.length-2;
				}else{
					this.items[i].posicion--;
				}

				if(this.items[i].posicion>=(this.colums.getCol()-1) || this.items[i].posicion<0){

					this.items[i].itemOutLeft = true;
				}else{

					this.items[i].itemOutLeft = false;
				}

				if(this.items[i].posicion >= 0 && this.items[i].posicion < this.colums.getCol()){
					this.items[i].activo=true;
				}else{
					this.items[i].activo=false;
				}
			}
		}
	}

	anterior(){
		if(this.items.length>1){

			for(var i in this.items){

				if( this.items[i].posicion == this.items.length-2 ){
					this.items[i].posicion = -1;
				}else{
					this.items[i].posicion++;
				}

				if(this.items[i].posicion>=(this.colums.getCol()-1) || this.items[i].posicion<0){

					this.items[i].itemOutLeft = true;
				}else{

					//this.items[i].itemOutLeft = false;
				}

				if(this.items[i].posicion >= 0 && this.items[i].posicion < this.colums.getCol()){
					this.items[i].activo=true;
				}else{
					this.items[i].activo=false;
				}
			}

		}
	}



/*GEG*/
siguienteBan(){

	if(this.items.length>1){

		for(var i in this.items){

			if( this.items[i].posicion ==  -1){
				this.items[i].posicion = this.items.length-2;
			}else{
				console.log("Paso");
				console.log(this.items[i].posicion);
				this.items[i].posicion--;
			}

			if(this.items[i].posicion>=(this.colums.getCol()-1) || this.items[i].posicion<0){

				this.items[i].itemOutLeft = true;
			}else{

				this.items[i].itemOutLeft = false;
			}

			if(this.items[i].posicion >= 0 && this.items[i].posicion < this.colums.getCol()){
				this.items[i].activo=true;
			}else{
				this.items[i].activo=false;
			}
		}
	}
}
siguienteBan2(){


		if(this.items.length>1){

		for(var i in this.items){


			if( this.items[i].posicion ==  -1){
				this.items[i].posicion = this.items.length-2;
			}else{
				this.items[i].posicion--;
			}

			if(this.items[i].posicion>=(this.colums.getCol()-1) || this.items[i].posicion<0){

				this.items[i].itemOutLeft = true;
			}else{

				this.items[i].itemOutLeft = false;
			}

			if(this.items[i].posicion >= 0 && this.items[i].posicion < this.colums.getCol()){
				this.items[i].activo=true;
			}else{
				this.items[i].activo=false;
			}


		}
	}
}
/*GEG*/





	iniciar(){
		var self = this;
		setTimeout(
			function(){
				setInterval(function(){
					self.siguiente()
				}
			,
			self.transitionTime)},self.transitionTime);
	}
}
