import { Component, OnInit, Input } from '@angular/core';
// import { QuillEditorModule } from '';

@Component({
	selector: 'pag-art-html',
	templateUrl:'pag-art-html.component.html'
})

export class PagArtHtmlComp{

// implements OnInit {
	@Input() artPag:any = false;

	public htmlEditor : boolean;

	constructor(
		// public quill:QuillEditorModule
		){

	}

	editando(edicion){
		this.artPag.descripcion = edicion.innerHTML;
	}

	change(htmlText){
		//console.log(htmlText);
	}
}
