import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DialogComp } from '../dialog/dialog.component';
import { DialogServ } from '../../services/dialog.service';
import { Ajax } from '../../services/ajax.service';


@Component({
	selector: 'slider-load',
	templateUrl:'slider-load.component.html'
})

export class SliderLoadComp implements OnInit {

	@Input() id:any = false;

	sliderSel:any = false;

	@Output() sliderSelected = new EventEmitter<any>()

	sliders:any = false;

	constructor(
		public dialogServ : DialogServ,
		public ajax : Ajax
	){
	}

	ngOnInit() {
		this.loadSliders();
	}

	openDialog() {
		this.dialogServ.open(this.id);
	}

	closeDialog() {
		this.dialogServ.close(this.id);
	}

	loadSliders(){
		this.ajax.get('slider/get').subscribe(
			res => {
				
				var resp:any = res;
				console.log(resp);

				if(resp.resp.resp=='true' && resp.tipo=='confirmo'){
					this.sliders = resp.resp.data;
				}
				console.log(this.sliders);
			},
			error => console.error('Error: '),() => console.log(''));
	}

	selectSlider(slider){
		this.sliderSel = slider;
		this.sliderSelected.emit(slider);
	}
}