import { Component,Injectable } from '@angular/core';
import { Header } from '../header/header.class';

@Injectable()

export class BodyServ {

	isPanel : any = false;
	is404 : any = false;
	header : any = new Header();
	nav : any = false;
	sidebar : any = false;
	section : any = false;
	footer : any = true;

	constructor() { }

	setIsPanel(is){
		this.isPanel = is;
	}

	setIs404(is){
		this.is404 = is;
	}

	setHeader(header){

		this.header = header;
	}

	setNav(nav){
		this.nav = nav;
	}

	setSidebar(sidebar){
		this.sidebar = sidebar;
	}

	setSection(section){
		this.section = section;
	}

	setFooter(footer){
		this.footer = footer;
	}

	getIsPanel(){

		return this.isPanel;
	}

	getIs404(){
		return this.is404;
	}

	getHeader(){
		return this.header;
	}

	getNav(){
		return this.nav;
	}

	getSidebar(){
		return this.sidebar;
	}

	getSection(){
		return this.section;
	}

	getFooter(){
		return this.footer;
	}

	reset(){
		// this.isPanel = false;
		this.is404 = false;
		// this.header = false;
		// this.nav = false;
		// this.sidebar = false;
		this.section = false;
		// this.footer = false;
	}
}
