import { Component,Injectable } from '@angular/core';
import { Ajax } from './ajax.service';
import { Router } from '@angular/router';

@Injectable()

export class MenuService{

	collapse : boolean = false;
	menus : any = false;
	menu : any = false;


	constructor(
		private router:Router,
		public ajax: Ajax
		) { }

	listar(){

		this.ajax.get('menu/listar')
		.subscribe(
			res => {
				var resp:any = res;
				console.log(resp);

				if(resp.tipo == 'confirmo' && resp.resp.resp=="true"){
					this.menu = resp.resp.data;
				}else{
					this.menu = [];
				}

				//this.menu = resp;
			},
			error => console.error('Error: '),() => console.log(''));
	}

	crear(menu){
		this.ajax.post('menu/crear',menu).subscribe(
			res => {
				var resp:any = res;
				console.log(resp);

				if(resp.tipo=='confirmo' && resp.resp.resp=="true"){
					this.router.navigate(["/panel/menus"]);
				}

				//else if(resp.tipo=='error'){
				// 	var errs = resp.resp.error.errores;
				// 	this.repoErr(errs);

				// 	console.log(errs);

				// }
			},
			error => console.error('Error: '),() => console.log(''));
	}

	editar(menu){

		console.log(menu);

		this.ajax.post('menu/editar/'+menu.id,menu).subscribe(
			res => {
				var resp:any = res;

				if(resp.tipo=='confirmo' && resp.resp.resp=="true"){
					//this.creada();

					this.router.navigate(["/panel/menus"]);

				}else if(resp.tipo=='error'){
					var errs = resp.resp.error.errores;
					//this.repoErr(errs);

				}
			},
			error => console.error('Error: '),() => console.log(''));
	}

	borrar(m){

		this.ajax.get('menu/borrar/'+m.id)
		.subscribe(
			res => {
				var resp:any = res;


				if(resp.resp.resp == 'true'){

					this.listar();
				}
			},

			error => console.error('Error: '),() => console.log(''));
	}

	toggleCollapseMenu(){

		this.collapse = !this.collapse;

	}
}
