import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { GaleriaServ } from '../../../../services/galeria.service';
import { DialogServ } from '../../../../services/dialog.service';

@Component({
	selector: 'galeria-list',
	templateUrl:'galeria-list.component.html'
})

export class GaleriaListComp implements OnInit{

	@Output() galeriaSelected = new EventEmitter<any>();
	public editar = false;
	public galeriaEdit;
	public nueva = false;

	constructor(
		public dialogServ : DialogServ,
		public galeriaServ:GaleriaServ
		){}

	ngOnInit(){
		this.galeriaServ.loadGalerias();
	}

	getImagen(img){

		var strImg = 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(img.imagen.nombre);
		return strImg;
	}

	selectGaleria(galeria){
		this.galeriaSelected.emit(galeria);
	}

	editGaleria(galeria){
		this.galeriaEdit = galeria;
		this.editar = true;
	}

	cerrarEdidtorGaleria(e){
		this.editar = false;
	}

	newGaleria(){
		this.nueva = true;
	}

	cerrarNuevaGaleria(e){
		this.galeriaServ.loadGalerias();
		this.nueva = false;
	}

}
