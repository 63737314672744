//import { Articulo } from '../articulo/articulo.interface';

export class ArtCarrera{// implements Articulo{
	id;
	idArt;
	pagina;
	carrera;
	categoriaArticulo;
	fechModificado;
	usrModificado;

	constructor(
		id=false,
		idArt=false,
		pagina=false,
		categoriaArticulo=false,
		carrera= new Carrera(),
		fechModificado=false,
		usrModificado=false
		){
		this.id = id;
		this.idArt = idArt;
		this.pagina = pagina;
		this.categoriaArticulo = categoriaArticulo;
		this.fechModificado = fechModificado;
		this.usrModificado = usrModificado;
		this.carrera = carrera;
	}

	setCategoriaArticulo(categoriaArticulo){
		this.categoriaArticulo = categoriaArticulo;
	}
}

export class Carrera{// implements Articulo{
	id;
	nombre;
	opciones;

	constructor(
		id=false,
		nombre=false,
		opciones=[]
		){
		this.id = id;
		this.nombre = nombre;
		this.opciones = opciones;
	}

}