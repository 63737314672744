import { Component, OnInit, Input } from '@angular/core';

import { GaleriaModalServ } from '../../../services/galeria-modal.service';

@Component({
	selector:'galeria-modal',
	templateUrl: './galeria-modal.component.html'
})

export class GaleriaModalComp implements OnInit {

	public activo : boolean = false;

	constructor (
		public galeriaModServ:GaleriaModalServ
		) {}

	ngOnInit() {

	}

	getImagen(img){

		var strImg = 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(img.imagen.nombre);
		return strImg;
	}
	// toggle(){
	// 	this.activo = !this.activo;
	// }

	// open(){
	// 	this.activo = true;
	// }

	// close(){
	// 	this.activo = false;
	// }

}
