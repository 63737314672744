import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

import { Ajax } from '../services/ajax.service';

import { BodyServ } from '../body/body.service';
import { Header } from '../header/header.class';
import { Pagina } from '../panel/modelos/pagina/pagina.class';
import { ArtHtmlComp } from '../art-html/art-html.component';

import { CategoriaSelectorComp } from '../categoria/categoria-selector/categoria-selector.component';

@Component({
	selector: 'c-section-home',
	templateUrl:'section-home.component.html'
})

export class SectionHomeComp implements OnInit {

	section : any = false;
	articulos : any = false;
	urls : any[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private titleServ : Title,
		private metaServ : Meta,
		public bodyServ : BodyServ,
		public ajax : Ajax,
		){}

	ngOnInit() {
		this.route.params.subscribe(params=> {


			this.urls = [];
			if(typeof params['url1'] !== "undefined"){

				this.urls.push(params['url1'].toString());

				if(typeof params['url2'] !== "undefined"){

					this.urls.push(params['url2'].toString());

					if(typeof params['url3'] !== "undefined"){

						this.urls.push(params['url3'].toString());

						if(typeof params['url4'] !== "undefined"){

							this.urls.push(params['url4'].toString());


						}
					}
				}
			}

			this.iniPagina();
		});

	}

	getSection(){
		this.section = this.bodyServ.getSection();
		/*console.log("1GEEEEEEEEEEEG");
		console.log(this.section);
		console.log("2GEEEEEEEEEEEG");*/
		return this.section;
	}

	urlsToString(){
		var url = "";

		for(let i of this.urls){
			url += i+"/";
		}

		return url;
	}

	iniPagina(){

		this.bodyServ.reset();

		var h = new Header("Home",false,'1');
		this.bodyServ.setHeader(h);
		this.bodyServ.setFooter(true);

		var url = this.urlsToString();
		/**
		console.log("1GEEEEEEEEEEEG");
		console.log(url);
		*/
		if( url == 'carreras/publicidad/' )
		{
			//Publicidad
			this.ajax.get('pagina/get/carreras/Publicidad')
			.subscribe(
				res => {

					var resp:any = res;

					if(resp.tipo=='confirmo'){
						var sectAux = resp.resp.data;

						var articulos = {};

						for(var i in sectAux.articulos){
							articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
						}

						this.section = sectAux;

						this.section.articulos = articulos;
						console.log(this.section);
						var etiquetas = 'La Fundación, Terciario, Estudiar, Carreras,';


						if(this.section.etiquetas){

							for(var z=0 ; z<this.section.etiquetas.length; z++){
								etiquetas += this.section.etiquetas[z].nombre+(this.section.etiquetas.length-1 > z ? ', ' : '');
							}

						}


						this.metaServ.removeTag('name="keywords"');


						this.metaServ.addTag({name:'keywords',content: etiquetas});

						if(typeof this.section.articulos['descripcion_breve'] != 'undefined'){
							if(this.section.articulos['descripcion_breve'].descripcion != ''){
								this.metaServ.removeTag('name="description"');
								this.metaServ.addTag({
									name:'description',
									content: (this.section.articulos['descripcion_breve'].descripcion).replace( /(<([^>]+)>)/ig, '')
								});
							}
						}

						this.titleServ.setTitle(this.section.titulo+' - La Fundación');

						this.bodyServ.setSection(this.section);
					}else{
						this.bodyServ.is404 = true;
						console.log(this.bodyServ.is404);
					}

				},
				error => console.error('Error: '));
		}else{
	this.ajax.get('pagina/get/'+url)
			.subscribe(
				res => {

					var resp:any = res;

					if(resp.tipo=='confirmo'){
						var sectAux = resp.resp.data;

						var articulos = {};

						for(var i in sectAux.articulos){
							articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
						}

						this.section = sectAux;

						this.section.articulos = articulos;
						console.log(this.section);
						var etiquetas = 'La Fundación, Terciario, Estudiar, Carreras,';


						if(this.section.etiquetas){

							for(var z=0 ; z<this.section.etiquetas.length; z++){
								etiquetas += this.section.etiquetas[z].nombre+(this.section.etiquetas.length-1 > z ? ', ' : '');
							}

						}


						this.metaServ.removeTag('name="keywords"');


						this.metaServ.addTag({name:'keywords',content: etiquetas});

						if(typeof this.section.articulos['descripcion_breve'] != 'undefined'){
							if(this.section.articulos['descripcion_breve'].descripcion != ''){
								this.metaServ.removeTag('name="description"');
								this.metaServ.addTag({
									name:'description',
									content: (this.section.articulos['descripcion_breve'].descripcion).replace( /(<([^>]+)>)/ig, '')
								});
							}
						}

						this.titleServ.setTitle(this.section.titulo+' - La Fundación');

						this.bodyServ.setSection(this.section);
					}else{
						this.bodyServ.is404 = true;
						console.log(this.bodyServ.is404);
					}

				},
				error => console.error('Error: '));
		}
		//this.ajax.get('pagina/get/carreras/Publicidad')

	}
}
