import { Component, OnInit } from '@angular/core';

import { Title,Meta } from '@angular/platform-browser';

import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { BodyServ} from './body/body.service';
import { Ajax} from './services/ajax.service';
import { DialogServ } from './services/dialog.service';
import { GaleriaServ } from './services/galeria.service';
import { GaleriaModalServ } from './services/galeria-modal.service';
import { LoadSpinnerServ } from './services/load-spinner.service';
import { MenuService } from './services/menu.service';
import { UsuarioService } from './services/usuario.service';
import { TgService } from './services/tg.service';

declare let gtag: Function;


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers:[
	BodyServ,
	DialogServ,
	GaleriaServ,
	GaleriaModalServ,
	LoadSpinnerServ,
	MenuService,
	UsuarioService,
	TgService,
	Ajax
	]
})

export class AppComponent implements OnInit {

	title = 'app';
	body:any = false;

	constructor(
		public bodyServ : BodyServ,
		private router: Router,
		private titleServ : Title,
		private metaServ : Meta,
		public loadSpinnerServ : LoadSpinnerServ
		){

	}

	ngOnInit() {

		this.router.events.subscribe((evt) => {

			// var noScroll = false;

			if (!(evt instanceof NavigationEnd)) {
				//Aca continua con la navegacion
				this.metaServ.removeTag('name="description"');
				this.metaServ.removeTag('name="keywords"');
				this.metaServ.addTag({
					name:'description',
					//content: 'La Fundación de Altos Estudios en Ciencias Comerciales cuenta con más de 60 años de trayectoria en la formación de profesionales altamente reconocidos en el mercado laboral. Las carreras dictadas por La Fundación, de tres años de duración, tienen una orientación netamente práctica que favorece la capacidad de adaptación de los alumnos a las distintas situaciones del mercado laboral.'
					content: 'La Fundación de Altos Estudios en Ciencias Comerciales es una institución con más de 60 años de trayectoria en la formación de profesionales altamente reconocidos en el mercado laboral. Ofrecemos carreras de tres años de duración, con una orientación netamente práctica que permite a nuestros alumnos adaptarse a las distintas situaciones del mercado laboral de manera eficiente.'
				});

				this.metaServ.addTag({
					name:'keywords',
					//contet:'la fundación, fundación de altos estudios, carreras cortas, carreras terciarias, estudios comerciales, ciencias empresariales, marketing, publicidad, administración de empresas, comercio internacional, recursos humanos, relaciones públicas, organización de eventos, diseño gráfico, técnico contable, finanzas, cursos cortos, licenciatura'
					contet:'la fundacion, fundacion de altos estudios, carreras cortas, carreras terciarias, estudios comerciales, ciencias empresariales, marketing, publicidad, administracion de empresas, comercio internacional, recursos humanos, relaciones publicas, organizacion de eventos, diseño grafico, tecnico contable, finanzas, cursos cortos, licenciatura, tecnico superior'
				});

				//this.titleServ.setTitle('La Fundación');
				this.titleServ.setTitle('Fundación de Altos Estudios en Ciencias Comerciales');
				return;
				// if(!(evt instanceof NavigationStart)){
				// }else{
					// var matchUrl = evt.url.match(/\#([A-Za-z0-9\-]*)/);
					// if(matchUrl){
					// 	console.log(document);
					// 	var el = document.getElementsByName(matchUrl[1]);
					// 	console.log(el.top);
					// 	var offsetTopIni = el.top + window.pageYOffset - 300;
					//
					// 	window.scrollTo({
					// 		top: offsetTopIni,
					// 		left: 0,
					// 		behavior: 'smooth'
					// 	});
					//
					// 	noScroll = true;
					// }
				// }
			}

			// var matchUrl = evt.url.match(/\#([A-Za-z0-9\-]*)/);
			// if(matchUrl){
			// 	var el = document.getElementsByName(matchUrl[1]);
			// 	console.log(el[0]);
			// 	if(typeof el[0] != 'undefined'){
			//
			// 		var offsetTopIni = el[0].top + window.pageYOffset - 300;
			//
			// 		window.scrollTo({
			// 			top: offsetTopIni,
			// 			left: 0,
			// 			behavior: 'smooth'
			// 		});
			//
			// 		noScroll = true;
			// 	}
			// }else{
				window.scrollTo(0, 0);

				//Pixel Config
				console.log(evt);
				if(evt.url=='/gracias'){
					gtag('event', 'conversion', {'send_to': 'AW-995718721/unjUCNKJ6pABEMHs5doD'});

				}
		    // gtag('config', 'UA-50921413-1', {'page_path':evt.urlAfterRedirects});

			// }

			//Aca la navegacion se corto

		});
	}

	ngAfterViewInit(){
		this.body = this.bodyServ;
	}


	getIsPanel(){
		return this.bodyServ.getIsPanel();
	}

	getHeader(){
		return this.bodyServ.getHeader();
	}
}
