import { Component, OnInit,ViewChild,ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { BodyServ } from '../../../body/body.service';
import { Header } from '../../../header/header.class';
import { Ajax } from '../../../services/ajax.service';

@Component({
  selector: 'cat-novedades-list',
  templateUrl:'cat-novedades-list.component.html'
})


export class CatNovedadesListComp implements OnInit{

  @ViewChild('descTop', {static: false}) descTop: ElementRef;
	@ViewChild('infoExtra', {static: false}) infoExtra: ElementRef;

  ops:any = [
	{
		cod:5,
		desc:"+INFO / ARANCELES",
		isSticky:false,
		isDisplayed:false,
		el:'infoExtra'
	}];


	@Input() categoria:any = false;
  public section:any = false;
  public novedades : any = false;

  public novsCant = 0;
  public novXPag = 10;
  public pags : any = false;
  public pagAct = 0;
  public firstLoad = true;
	public formDefValues:any = false;
	public formSubmitedStatus = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ajax : Ajax,
    public bodyServ : BodyServ
  ){}

  ngOnInit() {
		this.bodyServ.header.titulo = "Home";
		this.bodyServ.header.cod = '1';
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);
		this.section = this.bodyServ.getSection();
    this.loadNovs();

  	this.formDefValues = {
			'car-1':this.section.padre.titulo
		}
  }


  pagSiguiente(){
    this.pagAct++;
    this.loadNovs();
  }

  goPag(p){
    this.pagAct = p.num-1;
    this.loadNovs();
  }

  loadNovs(){
    var offset = this.pagAct*this.novXPag;

    this.ajax.get('pagina/hijas/'+this.section.id+'/'+encodeURI('titulo:descripcion_breve:imagen_chica')+'/'+this.novXPag+'/'+offset).subscribe(
      res => {

        var resp:any = res;


        if(resp.resp){
          if(resp.resp.resp == false){
            this.novedades = false;
            this.novsCant = 0;
          }else{

            for(var j in resp.resp.data.paginas){

              var articulos = {};

              for(var i in resp.resp.data.paginas[j].articulos){
                articulos[resp.resp.data.paginas[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data.paginas[j].articulos[i];
              }

              resp.resp.data.paginas[j].articulos = articulos;
            }

            this.novedades = resp.resp.data.paginas;
            this.novsCant = resp.resp.data.cantPag;
          }


          if(!this.firstLoad){
        		var el = this['descTop'].nativeElement.getBoundingClientRect();

        		var offsetTopIni = el.top + window.pageYOffset - 50;

        		window.scrollTo({
        			top: offsetTopIni,
        			left: 0,
        			behavior: 'smooth'
        		});
          }

          this.firstLoad = false;


          this.loadPags();
        }
      },
      error => console.error('Error: '),() => console.log(''));
  }

  loadPags(){
    var cantPags = Math.ceil(this.novsCant/this.novXPag);

    if(cantPags==1){
      this.pags = false;
    }else{
      this.pags = [];

      var offset = this.pagAct*this.novXPag;

      var lastPag = -1;
      for(var i=0;i<cantPags;i++){

        //Tienen que listarse:
        //  - La primera y última
        //  - 2 antes y 2 despues de la actual
        var cont = true;

        if(
          (i==0  ||
          i==(cantPags-1)) ||
          ((this.pagAct-i)<=2 &&
          (this.pagAct-i)>=(-2))
        ){
          cont = false;
        }

        if(cont){
          continue;
        }

        var separator = false;
        if(lastPag != i-1){
          separator = true;

          this.pags.push({
            num: false,
            separator: separator
          });

        }
        lastPag = i;

        this.pags.push({
          num: i+1,
          separator: false
        });

      }

    }
  }


  getFormDefValues(){
    var values = {};
    return values;
  }

  formSubmited(e){
    if(e){
      // console.log(this.section.padre.url);
      this.router.navigate(['gracias',this.section.padre.url]);
    }
  }

  formSubmitedMadryn(e){
    if(e){
      console.log(this.section.padre.url);
      this.router.navigate(['gracias','madryn',this.section.padre.url]);
    }
  }


  getImagen(art){
    return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
  }

}
