import { Component, Input } from '@angular/core';
import { BodyServ } from '../../body/body.service';

@Component({
	selector:'breadcrumb',
	templateUrl: './breadcrumb.component.html'
})

export class Breadcrumb{

  section;

	constructor (
    public bodyServ:BodyServ
  ) {}

	ngOnInit() {
		this.section = this.bodyServ.getSection();
  }

}
