//import { Articulo } from '../articulo/articulo.interface';

export class ArtForm{// implements Articulo{

    id;
    idArt;
    pagina;
    form;
    style;
    titulo;
    respAutomatica;
    respAsunto;
    respDescripcion;
    categoriaArticulo;
    fechModificado;
    usrModificado;

	constructor(
    id=false,
    idArt=false,
    pagina=false,
    form=false,
    style=false,
    titulo=false,
    respAutomatica=false,
    respAsunto=false,
    respDescripcion=false,
    categoriaArticulo=false,
    fechModificado=false,
    usrModificado=false
		){
    this.id = id;
    this.idArt = idArt;
    this.pagina = pagina;
    this.form = form;
    this.style = style;
    this.titulo = titulo;
    this.respAutomatica = respAutomatica;
    this.respAsunto = respAsunto;
    this.respDescripcion = respDescripcion;
    this.categoriaArticulo = categoriaArticulo;
    this.fechModificado = fechModificado;
    this.usrModificado = usrModificado;
	}

	setCategoriaArticulo(categoriaArticulo){
		this.categoriaArticulo = categoriaArticulo;
	}
}
