import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'dropdown',
    template: `
      <div [formGroup]="form">
      <label class="color-white">{{field.etiqueta}}</label>
        <div class='row'>
          <div class='col-xs-12'>
            <select class="form-control" [id]="field.cod" [formGroupName]="field.cod" (change)="changeDropdownValue($event)">
              <option *ngFor="let opt of field.opciones" [ngValue]="opt.cod" [selected]="form.controls[field.cod].value == opt.cod">{{opt.etiqueta}}</option>
            </select>
          </div>
        </div>
      </div>
    `
})
export class DropDownComponent  implements OnInit{
    @Input() field:any = {};
    @Input() form:FormGroup;

    constructor() {
    }
    ngOnInit() {
      console.log(this.field);
        console.log(this.form);
    }


    changeDropdownValue(e){
      this.form.controls[this.field.cod].setValue(e.target.value);
    }
}
