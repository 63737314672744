import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { BodyServ } from '../../../../body/body.service';
import { MenuService } from '../../../../services/menu.service';
import { Header } from '../../../../header/header.class';
import { Ajax } from '../../../../services/ajax.service';
import { Menu } from '../menu.class';
import { UsuarioService } from '../../../../services/usuario.service';


@Component({
	selector: 'c-menu-editar',
	templateUrl:'menu-editar.component.html'
})

export class MenuEditarComp implements OnInit{

	menus : any = false;
	menu : Menu = new Menu();
	menuPadre: Menu = new Menu();

	constructor(
		public ajax : Ajax,
		public bodyServ : BodyServ,
		private route: ActivatedRoute,
		private router:Router,
		private menuServ:MenuService,
		public usrServ : UsuarioService
		){}

	ngOnInit() {

		this.usrServ.isLoged();

		var h = new Header("Home",false,'2');

		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
		this.bodyServ.header.cod=2;

		this.route.params.subscribe(params=> {

			this.menu.id = params['id'].toString();

			this.ajax.get('menu/getId/'+this.menu.id)
			.subscribe(
				res => {
					var resp:any = res;
					this.menuPadre = new Menu(0,false,false,false,resp.menus);

					/*
					for(var i in this.menuPadre.hijos){
						this.menuPadre.hijos[i] = this.menuPadre;
					}
					*/

					this.menus = this.recuToArray(resp.menus);
					this.menu = resp.menu;

					console.log(this.menu);
					if(this.menu.padre.id == ""){
						this.menu.padre = 0;
					}else{
						for(var i in this.menus){
							if(this.menu.padre.id == this.menus[i].id ){
								this.menu.padre = this.menus[i];
							}
						}

					}
				},
				error => console.error('Error: '),() => console.log(''));
		});



	}

	noAutoreferencia(ms,menu:any =false){
		//Devuelve un array sin si mismo y sin los hijos

		var menu = (menu?menu:this.menu);

		var arrMs = ms;

		if(ms.length>0){

			if(menu.hijos.length>0){
				//recursión para comprobar que no alla hijos

				for(var j in menu.hijos){
					arrMs = this.noAutoreferencia(arrMs,menu.hijos[j]);
				}
			}

			//recorro todos los menus comprobando de que no sean el menu

			for(var i in arrMs){
				//Elimino el menu

				if(arrMs[i].id == menu.id){
					arrMs.splice(i,1);
				}
			}
		}else{

			return [];
		}

		return arrMs;
	}

	recuToArray(r){

		var arr = [];
		arr = arr.concat(r);

		for(var i in r){
			if(r[i].hijos){
				arr = arr.concat(this.recuToArray(r[i].hijos));
			}
		}

		return arr;
	}

	ordenChange(e){
		//console.log(e);
	}

	ordenChange2(e){
		//console.log(this.menu.padre.hijos.length);
	}

	enviar(){
		//pasanding
		this.menuServ.editar(this.menu);

	}

	tieneHermanos(){

		//console.log(this.menu.padre.hijos);
		//console.log(this.menu.padre);

		if(typeof this.menu.padre.hijos != 'undefined'){
			return (this.menu.padre.hijos.length >=0);
		}
	}
}
