import { Component, OnInit, Input, Output, EventEmitter,ViewChild,ElementRef } from '@angular/core';

import { DialogServ } from '../../../services/dialog.service';

@Component({
	selector: 'html-editor-toolbar',
	templateUrl:'html-editor-toolbar.component.html'
})

export class HtmlEditorToolbarComp /* implements OnInit*/ {


	@Input() id:any = 'untEst';
	public imgSelected : any = false;
	// public lasBlur

	public commands : any = [
	{command:"bold",desc:''},
	{command:"italic"},
	{command:"underline"},
	{command:"strikeThrough"},
	{command:"justifyLeft"},
	{command:"justifyCenter"},
	{command:"justifyRight"},
	{command:"justifyFull"},
	{command:"indent"},
	{command:"outdent"},
	{command:"insertUnorderedList"},
	{command:"insertOrderedList"},
	{command:"h1"},
	{command:"h2"},
	{command:"createlink"},
	{command:"unlink"},
	{command:"insertimage"},
	{command:"p"},
	{command:"subscript"},
	{command:"superscript"}

	];

	constructor(
		public dialogServ : DialogServ
	){}

	action(command, value:any=false){

		if (command == 'h1' || command == 'h2' || command == 'p') {
			document.execCommand('formatBlock', false, command);
		}else if (command == 'forecolor' || command == 'backcolor') {
			document.execCommand(command, false, value);
		// }else if(command == 'insertimage'){
			// this.dialogServ.open(this.id);
		}else if (command == 'createlink' || command == 'insertimage') {
			var url = prompt('Link (No borrar http://): ', 'http:\/\/');

			if(command == 'createlink'){
				var selection = document.getSelection();
				document.execCommand('createLink', true, url);
				selection.anchorNode.parentElement.setAttribute('target', '_blank');
			}else{

				document.execCommand(command, false, url);
			}

		} else document.execCommand(command, false, null);


	}

	insertImg(){

		if(this.imgSelected){

			var urlImg =  'https:\/\/lafundacion.edu.ar\/new_php_v2\/public\/upload\/img\/'+this.imgSelected.nombre;
			console.log(urlImg);
			var ec = document.execCommand('insertImage', false,urlImg);

			console.log(ec);

			this.closeDialog();
		}else{
			alert("Seleccione una imagen por favor.");
		}
	}

	onImgSelected(e){
		this.imgSelected = e;
	}

	closeDialog() {
		this.dialogServ.close(this.id);
	}


}
