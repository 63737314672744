import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';

import { BodyServ } from '../../body/body.service';
import { FormGroup, FormControl,FormBuilder, Validators } from '@angular/forms';
import { TgService } from '../../services/tg.service';
import { PagoService } from '../../services/pago.service';

@Component({
  selector: 'datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.css'],
  providers:[
    PagoService
  ]

})
export class DatosPersonalesComponent implements OnInit {

  @Output() sendData = new EventEmitter<any>();

  datosPersonalesForm = this.fb.group({
    nombre: ['',Validators.required],
    apellido:['',Validators.required],
    tipodoc:['1',Validators.required],
    nrodoc:['',Validators.required],
    email:['',[Validators.required,Validators.email]],
    celular:['',Validators.required],
    sede:['1',Validators.required],
    matricula:['',Validators.required]
  });

  constructor(
    private fb: FormBuilder,
		private router: Router,
    public bodyServ : BodyServ,
    public tgServ : TgService,
    public pagoServ : PagoService
  )
  { }

  ngOnInit() {
    console.log(this.tgServ);
    this.tgServ.getTipodoc();
  }

  guardarDatosPersonales(){
    // this.pagoServ.alumno = this.datosPersonalesForm.value;
    // this.pagoServ.crearAlumno(this.datosPersonalesForm.value);
    this.sendData.emit(this.datosPersonalesForm.value);
    // console.log(this.datosPersonalesForm.value);
  }



  testAlumno(){
    console.log(this.pagoServ.alumno);
  }
}
