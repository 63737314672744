import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuService } from '../../../../services/menu.service';
import { BodyServ } from '../../../../body/body.service';
import { Header } from '../../../../header/header.class';
import { Ajax } from '../../../../services/ajax.service';
import { Menu } from '../menu.class';
import { UsuarioService } from '../../../../services/usuario.service';

@Component({
	selector: 'c-menu-crear',
	templateUrl:'menu-crear.component.html'
})

export class MenuCrearComp implements OnInit{

	menus : any = false;
	menu : Menu = new Menu();
	menuPadre: Menu = new Menu();

	constructor(
		private router:Router,
		public ajax : Ajax,
		public bodyServ : BodyServ,
		public menuServ : MenuService,
		public usrServ : UsuarioService
		){}

	ngOnInit() {

		this.usrServ.isLoged();

		this.iniPagina();

		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
		this.bodyServ.header.cod=2;

		this.menu.orden = 0;
		this.menu.padre = 0;
		this.menu.habilitado = true;
	}

	iniPagina(){

		this.ajax.get('menu/nuevo')
		.subscribe(
			res => {
				var resp:any = res;
				//this.menus = resp;



				if(resp.tipo == 'confirmo' && resp.resp.resp=="true"){
					this.menuPadre = new Menu(0,false,false,false,resp.resp.data);

					this.menus = resp.resp.data;
					this.menus = this.recuToArray(resp.resp.data);
				}else{
					this.menus = [];
					this.menus = [];
					this.menuPadre = new Menu(0,false,false,false,resp);
				}

			},
			error => console.error('Error: '),() => console.log(''));
	}

	recuToArray(r){

		var arr = [];
		arr = arr.concat(r);

		for(var i in r){
			if(r[i].hijos){
				arr = arr.concat(this.recuToArray(r[i].hijos));
			}
		}

		return arr;
	}

	ordenChange(e){
	}

	ordenChange2(e){
		//console.log(this.menu.padre.hijos.length);
	}

	enviar(){
		this.menuServ.crear(this.menu);
	}

	tieneHermanos(){
		//console.log(this.menu.padre.hijos);

		if(typeof this.menu.padre.hijos == 'object'){
			return (this.menu.padre.hijos.length >=0);
		}

		return false;
	}

	/*
	repoErr(e){
		console.log("err");
		this.dialogHTML = '';
		for(var i in e){
			this.dialogHTML += "<p>"+e[i].descripcion+"</p>";
		}
		console.log(this.dialogHTML);
		this.openDialog();
	}*/
}
