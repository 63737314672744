import { Component,Injectable } from '@angular/core';
//import { Http,Response,Headers,RequestMethod,RequestOptions  } from '@angular/http';
import { HttpClient,HttpHeaders } from '@angular/common/http';


@Injectable()

export class Ajax{
	base : string = 'https://lafundacion.edu.ar/new_php_v2/index.php/';

	constructor(
		public http: HttpClient
		) { }

	get(url,data?){

		var getVars = "";

		if(data){
			getVars = "?";
			var mas = true;
			for(var i in data){

				getVars += i+"="+data[i]+"&";
			}
		}

		return this.http.get(this.base+url+getVars);
	}

	post(url,data){
		// let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
		//
		// let options = new RequestOptions({ headers: headers});
		//
		//
		// return this.http.post(this.base+url,{data:data},options);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/x-www-form-urlencoded'
			})
		};

		return this.http.post(this.base+url,{data:data},httpOptions);
	}
}
