import { Component, OnInit, Input } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';
import { Colums } from '../../../res/colums.class';
import { Slider } from '../../../slider/slider-test/slider.class';
import {Router} from "@angular/router";

@Component({
	selector: 'cat-madryn-home',
	templateUrl:'cat-madryn-home.component.html'
})

export class CatMadrynHomeComp implements OnInit {

	public itemsImg = [];
	public sliderImg;

	@Input() categoria:any = false;

	section : any = false;
	carreras : any = false;

	public formSubmitedStatus = false;

	constructor(
		public bodyServ : BodyServ,
			public router : Router,
		public ajax : Ajax
		){}

	ngOnInit(){

		this.section = this.bodyServ.getSection();

		this.getCarreras();

	}

	getCarreras(){
		this.ajax.get('pagina/hijas/104')
		.subscribe(
			res => {
				var resp:any = res;

				for(var j in resp.resp.data){

					var articulos = {};

					for(var i in resp.resp.data[j].articulos){
						articulos[resp.resp.data[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data[j].articulos[i];
					}

					resp.resp.data[j].articulos = articulos;
				}

				this.carreras = resp.resp.data;
			},
			error => console.error('Error: '),() => console.log('Hubo un error'));
	}

	getImagen(art){
		return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
	}

	formSubmited(e){
			if(e){
				this.router.navigate(['gracias']);
				// this.formSubmitedStatus = true;
			}
	}
}
