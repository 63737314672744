import { Component } from '@angular/core';

import { BodyServ } from '../../body/body.service';
import { UsuarioService } from '../../services/usuario.service';
import { Header } from '../header.class';

@Component({
	selector: 'c-header-panel-usr',
	templateUrl:'header-panel-usr.component.html'
})

export class HeaderPanelUsrComp{
	header : any = 'false';

	constructor(
		public bodyServ : BodyServ,
		public usrServ : UsuarioService
	){}

	getHeader(){
		this.header = this.bodyServ.getHeader();
		return this.header;
	}
}
