import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagosComponent } from './pagos/pagos.component';
// import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';

const routes: Routes = [
  {
    path: '',
    component: PagosComponent
  }
  // ,
  // {
  //   path: 'datos-personales',
  //   component: DatosPersonalesComponent
  // },
  // {
  //   path: 'datos-tarjeta',
  //   component: PagosComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PagosRoutingModule { }
