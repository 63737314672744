import { Component, Input } from '@angular/core';
import { Ajax } from '../../../../../services/ajax.service';
import { MenuService } from '../../../../../services/menu.service';

@Component({
	selector: 'c-menu-iterator',
	templateUrl:'menu-iterator.component.html'
})

export class MenuIteratorComp{

	@Input() menus : any = false;

	constructor(
		public ajax : Ajax,
		private menuServ:MenuService
		){}

	borrar(m){
		console.log(m);
		this.menuServ.borrar(m);

	}
}
