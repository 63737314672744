import { Component, OnInit, Input } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import { LoadSpinnerServ } from '../../../services/load-spinner.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';
import { Colums } from '../../../res/colums.class';
import { Slider } from '../../../slider/slider-test/slider.class';

import {Router} from "@angular/router";

@Component({
	selector: 'cat-carreras',
	templateUrl:'cat-carreras.component.html'
})

export class CatCarrerasComp implements OnInit {


	public itemsImg = [];
	public sliderImg;
	public imgsSlider;
	public loaderOfCount = 0;
	public loaderOfNumber = 2;

	@Input() categoria:any = false;
	section : any = false;
	carreras : any = false;

	public formSubmitedStatus = false;

	constructor(
		public bodyServ : BodyServ,
		public ajax : Ajax,

		public router:Router,
		 public loadSpinnerServ : LoadSpinnerServ
		){
		 this.loadSpinnerServ.on = true;
	}

	ngOnInit(){


		this.ajax.get('slider/getId/2').subscribe(
			res => {

				var resp:any = res;

				if(resp.resp.resp){
					this.imgsSlider = resp.resp.data.imgs;

					var c = new Colums();

					c.setXsCols(1);

					this.sliderImg = new Slider(c,600,this.imgsSlider,this.itemsImg,6000);

					this.itemsImg = this.sliderImg.contenidosToItems(this.imgsSlider);

					this.sliderImg.iniciar();


				}

				this.setOfLoader();

			},
			error => console.error('Error: '),
			() => console.log('consulta completa'));



		this.section = this.bodyServ.getSection();


		this.getCarreras();

	}

	getCarreras(){
		console.log(this.section);
		this.ajax.get('pagina/hijas/'+this.section.id+'/'+encodeURI('img_chica:descripcion_breve'))
		.subscribe(
			res => {
				var resp:any = res;

				for(var j in resp.resp.data){

					var articulos = {};

					for(var i in resp.resp.data[j].articulos){
						articulos[resp.resp.data[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data[j].articulos[i];
					}

					resp.resp.data[j].articulos = articulos;
				}

				this.carreras = resp.resp.data;
				this.setOfLoader();
			},
			error => console.error('Error: '),() => console.log('Hubo un error'));
	}

	getImagen(art){
		console.log(art);
		return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
	}

	setOfLoader(){
		 this.loaderOfCount++;
		 if(this.loaderOfCount>= this.loaderOfNumber){
		 	this.loadSpinnerServ.on = false;
		 }
	}

	formSubmited(e){
			if(e){
				this.formSubmitedStatus = true;
			}
	}

	goTo(url){
		// console.log("Pressedddddd: "+url);
		this.router.navigate([url]);
	}


}
