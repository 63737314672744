import { Component, OnInit, Input, Output, EventEmitter,ViewChild,ElementRef } from '@angular/core';

@Component({
	selector: 'html-editor',
	templateUrl:'html-editor.component.html',
	styleUrls: ['./html-editor.component.css']
})

export class HtmlEditorComp{

	@Input() textHtml : any = false;
	@Output() updateHtml = new EventEmitter();


	constructor(){}

	ngAfterViewInit(){}

	changeHtml(unDato){
		this.textHtml = unDato;
		this.updateHtml.emit(unDato);
	}


}
