import { Component, OnInit, Input } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';

@Component({
	selector: 'cat-post-html',
	templateUrl:'cat-post-html.component.html'
})

export class CatPostHtmlComp implements OnInit {

	// public itemsImg = [];
	// public sliderImg;
	// public imgsSlider;


	@Input() categoria:any = false;
	section : any = false;

	constructor(
		public bodyServ : BodyServ,
		public ajax : Ajax
		){}

	ngOnInit(){

		this.section = this.bodyServ.getSection();
    //		this.section = this.bodyServ.getSection();
	}

	getImagen(art){
		return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
	}
}
