import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { BodyServ } from '../../body/body.service';
import { Header } from '../../header/header.class';
import { Ajax } from '../../services/ajax.service';

@Component({
  selector: 'pg-landing-charlas',
  templateUrl:'charlaFinanzas.component.html'
})


export class InscCharlasFinanzasComp implements OnInit{

  public section:any = false;
  public formSubmitedStatus = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ajax : Ajax,
    public bodyServ : BodyServ
  ){}

  ngOnInit() {

    this.bodyServ.setSection(false);

    // this.ajax.get('pagina/get/landing')
    this.ajax.get('pagina/get/ingresantes/charlas-informacion')
		.subscribe(
			res => {


			var resp:any = res;

      if(resp.tipo=='confirmo'){
        var sectAux = resp.resp.data;

        var articulos = {};

        for(var i in sectAux.articulos){
          articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
          console.log(sectAux.articulos[i]);

        }

        this.section = sectAux;
        this.section.articulos = articulos;
        this.bodyServ.setSection(this.section);
      }
			},
			error => console.error('Error: '),() => console.log('Hubo un error'));

		this.bodyServ.header.titulo = "Home";
		this.bodyServ.header.cod = '1';
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);
  }

  formSubmited(e){
      if(e){
  			this.router.navigate(['gracias']);
        this.formSubmitedStatus = true;
      }
  }

}
