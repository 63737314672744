import { Component, OnInit, AfterViewInit } from '@angular/core';

import { BodyServ } from '../../../../body/body.service';
import { Header } from '../../../../header/header.class';
import { Ajax } from '../../../../services/ajax.service';
import { MenuService } from '../../../../services/menu.service';
import { UsuarioService } from '../../../../services/usuario.service';

@Component({
	selector: 'c-menu-list',
	templateUrl:'menu-list.component.html'
})

export class MenuListComp implements OnInit{
	menu : any = false;

	constructor(
		public ajax : Ajax,
		public menuServ : MenuService,
		public bodyServ : BodyServ,
		public usrServ : UsuarioService
		){}

	ngOnInit() {

		this.usrServ.isLoged();

		this.menuServ.listar();
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
		this.bodyServ.header.cod=2;
	}

}
