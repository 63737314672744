import { Component, Input } from '@angular/core';

@Component({
	selector: 'c-art-slider',
	templateUrl:'art-slider.component.html'
})

export class ArtSliderComp{
	@Input() articulo : any = false;
	constructor(){}
}