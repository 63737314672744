import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ResModule } from '../res/res.module';
import { PagosRoutingModule } from './pagos-routing.module';

import { PagosComponent } from './pagos/pagos.component';
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';
import { DatosTarjetaComponent } from './datos-tarjeta/datos-tarjeta.component';
import { DatosPagoComponent } from './datos-pago/datos-pago.component';



@NgModule({
  declarations: [
    PagosComponent,
    DatosPersonalesComponent,
    DatosTarjetaComponent,
    DatosPagoComponent
  ],
  imports: [
    CommonModule,
    PagosRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ResModule
  ]
})

export class PagosModule {

}
