import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogServ } from '../../services/dialog.service';


import { BodyServ } from '../../body/body.service';
import { Header } from '../../header/header.class';
import { UsuarioService } from '../../services/usuario.service';

import { Ajax } from '../../services/ajax.service';

@Component({
  selector: 'panel-multimedia',
	templateUrl:'panel-multimedia.component.html'
})

export class PanelMultimediaComp implements OnInit {
  public idDialog = 'panelMult';
  public imagenes:any;
  public imgsCant = 0;
  public imgXPag = 10;
  public imgToOpen : any = false;
  public pags : any = false;
  public pagAct = 0;
  public idImgUpload = 'mult-upload';


	constructor(
		private router:Router,
		public ajax : Ajax,
		public bodyServ : BodyServ,
    public dialogServ : DialogServ,
		public usrServ : UsuarioService
		//,	public plService : PaginaService
		){
  }

	ngOnInit() {
		this.usrServ.isLoged();
    this.iniPanelMultimedia();

		var h = new Header("Home",false,'2');

		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
	}

  iniPanelMultimedia(){
    this.loadImgs();

  }

  pagSiguiente(){
    this.pagAct++;
    this.loadImgs();
  }

  goPag(p){
    this.pagAct = p.num-1;
    this.loadImgs();
  }

  loadImgs(){
    var offset = this.pagAct*this.imgXPag;
    this.ajax.get('imagen/get/'+this.imgXPag+'/'+offset).subscribe(
      res => {

        var resp:any = res;

        if(resp.resp){
          this.imagenes = resp.imgs.imgs;

          // if(resp.imgs.cantImgs!=this.imgsCant){
            this.imgsCant = resp.imgs.cantImg;
            this.loadPags();
          // }
        }
      },
      error => console.error('Error: '),() => console.log(''));
  }

  loadPags(){
    console.log(this.pagAct);
    var cantPags = Math.ceil(this.imgsCant/this.imgXPag);

    if(cantPags==1){
      this.pags = false;
    }else{
      this.pags = [];

      var offset = this.pagAct*this.imgXPag;

      // var maxPags = (cantPags>6;);
      var lastPag = -1;
      for(var i=0;i<cantPags;i++){

        //Tienen que listarse:
        //  - La primera y última
        //  - 2 antes y 2 despues de la actual
        var cont = true;

        if(
          (i==0  ||
          i==(cantPags-1)) ||
          ((this.pagAct-i)<=2 &&
          (this.pagAct-i)>=(-2))
        ){
          cont = false;
        }

        if(cont){
          continue;
        }

        var separator = false;
        if(lastPag != i-1){
          separator = true;

          this.pags.push({
            num: false,
            separator: separator
          });

        }
        lastPag = i;

        this.pags.push({
          num: i+1,
          separator: false
        });

      }
    }
  }

  encodeURI(img){
    return encodeURI(img);
  }

  openImg(img){
    this.imgToOpen = img;

    console.log(this.dialogServ);
    this.dialogServ.open(this.idDialog);
  }

	closeImg() {
		this.dialogServ.close(this.idDialog);
    this.imgToOpen = false;
	}


  openImgUpload(){
    this.dialogServ.open('img-upload-dialog');
  }

	closeImgUpload() {
		this.dialogServ.close('img-upload-dialog');
	}
}
