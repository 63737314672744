import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { PagArtHtmlComp } from '../pag-art-html/pag-art-html.component';
import { PagArtsListComp } from '../pag-arts-list/pag-arts-list.component';

import { ArtHtml } from '../../../../art-html/art-html.class';
import { ArtImg } from '../../../../art-img/art-img.class';
import { ArtSlider } from '../../../../art-slider/art-slider.class';
import { ArtGaleria } from '../../../../art-galeria/art-galeria.class';
import { ArtCarrera } from '../../../../art-carrera/art-carrera.class';
import { ArtForm } from '../../../../art-form/art-form.class';

import { Pagina } from '../pagina.class';
import { BodyServ } from '../../../../body/body.service';
import { Header } from '../../../../header/header.class';
import { DialogServ } from '../../../../services/dialog.service';
import { UsuarioService } from '../../../../services/usuario.service';

import { Ajax } from '../../../../services/ajax.service';
import { PaginaService } from '../../../../services/pagina-list.service';

@Component({
	selector: 'c-pagina',
	templateUrl:'pagina-editar.component.html'
})

export class PaginaEditarComp implements OnInit {
	dialogId : any = 'crearpag';
	categorias:any = false;
	paginas:any = false;
	dialogHTML : string = '';

	pagina : Pagina = new Pagina();

	//categoriaSel : any = false;

	constructor(
		private route: ActivatedRoute,
		private router:Router,
		public ajax : Ajax,
		public bodyServ : BodyServ,
		public dialog : DialogServ,
		public usrServ : UsuarioService
	){
	}

	ngOnInit() {
		this.usrServ.isLoged();
		var h = new Header("Home",false,'2');
		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);

		this.route.params.subscribe(params=> {

			this.pagina = new Pagina(params['id'].toString());

			this.ajax.get('pagina/getId/'+this.pagina.id)
			.subscribe(
				res => {
					var resp:any = res;

					this.constructPagina(resp);

					this.iniPagina();
				},
				error => console.error('Error: '));
			});
		}

		iniPagina(){
			console.log("Editing pagin");
			this.ajax.get('pagina/nuevo2')
			.subscribe(
				res => {
					var resp:any = res;
					this.categorias = resp.categorias;
					this.paginas = resp.paginas;

					/*
					if(this.categorias[0]==undefined){
					this.pagina.categoria = false;
				}else{
				this.pagina.categoria = this.categorias[0];
			}
			*/

			this.paginas.unshift(new Pagina(0,'','Sin padre'));

			for(var i in this.categorias){
				if(this.categorias[i].id == this.pagina.categoria.id){
					this.pagina.categoria = this.categorias[i];
					break;
				}
			}


			this.pagina.padre = this.pagSearchId(this.pagina.padre,this.paginas);

		},
		error => console.error('Error: '));
	}

	pagSearchId(p,pags){
		for(var i in pags){
			if(p.id ==pags[i].id){

				return pags[i];

			}else if(pags[i].hijos != null){

				var ph = this.pagSearchId(p,pags[i].hijos);

				if(ph){

					return ph;
				}
			}
		}
		return false;
	}

	noAutoreferencia(pags,pagina:any =false){
		//Devuelve un array sin si mismo y sin los hijos

		var pagina = (pagina?pagina:this.pagina);

		var arrPags = pags;

		if(pags.length>0){

			if(pagina.hijos.length>0){
				//recursión para comprobar que no alla hijos

				for(var j in pagina.hijos){
					arrPags = this.noAutoreferencia(arrPags,pagina.hijos[j]);
				}
			}

			//recorro todos los menus comprobando de que no sean el pagina

			for(var i in arrPags){
				//Elimino el pagina

				if(arrPags[i].id == pagina.id){
					arrPags.splice(i,1);
				}
			}
		}else{

			return [];
		}

		return arrPags;
	}

	constructPagina(p){

		if(typeof p.articulos == 'object'){
			var arts = {};
			for(var i in p.articulos){


				if(p.articulos[i].categoriaArticulo.tipo == 1){

					arts[p.articulos[i].categoriaArticulo.codigo] = new ArtHtml(
						p.articulos[i].id,
						p.articulos[i].idArt,
						p.articulos[i].pagina,
						p.articulos[i].descripcion,
						p.articulos[i].categoriaArticulo,
						p.articulos[i].fechModificado,
						p.articulos[i].usrModificado);

					}else if(p.articulos[i].categoriaArticulo.tipo == 2){
						arts[p.articulos[i].categoriaArticulo.codigo] = new ArtImg(
							p.articulos[i].id,
							p.articulos[i].idArt,
							p.articulos[i].pagina,
							p.articulos[i].imagen,
							p.articulos[i].categoriaArticulo,
							p.articulos[i].fechModificado,
							p.articulos[i].usrModificado
						);
					}else if(p.articulos[i].categoriaArticulo.tipo == 3){
						arts[p.articulos[i].categoriaArticulo.codigo] = new ArtSlider(
							p.articulos[i].id,
							p.articulos[i].idArt,
							p.articulos[i].pagina,
							p.articulos[i].categoriaArticulo,
							p.articulos[i].slider,
							p.articulos[i].fechModificado,
							p.articulos[i].usrModificado
						);
					}else if(p.articulos[i].categoriaArticulo.tipo == 4){
						arts[p.articulos[i].categoriaArticulo.codigo] = new ArtGaleria(
							p.articulos[i].id,
							p.articulos[i].idArt,
							p.articulos[i].pagina,
							p.articulos[i].categoriaArticulo,
							p.articulos[i].galeria,
							p.articulos[i].fechModificado,
							p.articulos[i].usrModificado
						);
					}else if(p.articulos[i].categoriaArticulo.tipo == 5){
						arts[p.articulos[i].categoriaArticulo.codigo] = new ArtCarrera(
							p.articulos[i].id,
							p.articulos[i].idArt,
							p.articulos[i].pagina,
							p.articulos[i].categoriaArticulo,
							p.articulos[i].carrera,
							p.articulos[i].fechModificado,
							p.articulos[i].usrModificado
						);
					}else if(p.articulos[i].categoriaArticulo.tipo == 6){
						arts[p.articulos[i].categoriaArticulo.codigo] = new ArtForm(
							p.articulos[i].id,
							p.articulos[i].idArt,
							p.articulos[i].pagina,
							p.articulos[i].form,
							p.articulos[i].style,
							p.articulos[i].titulo,
							p.articulos[i].respAutomatica,
							p.articulos[i].respAsunto,
							p.articulos[i].respDescripcion,
							p.articulos[i].categoriaArticulo,
							p.articulos[i].fechModificado,
							p.articulos[i].usrModificado
						);
					}
				}
				p.articulos= arts;
			}

			var strEtiquetas = "";

			if(typeof p.etiquetas == 'object'){
				var j = 1;
				for(var i in p.etiquetas){
					strEtiquetas += p.etiquetas[i].nombre;


					if(j<p.etiquetas.length){
						strEtiquetas += ",";
					}
					j++;
				}
			}

			p.etiquetas = strEtiquetas;


			this.pagina = new Pagina(p.id,false,p.titulo,p.categoria,p.padre,false,p.articulos,p.etiquetas,p.habilitado,p.fechModificado,p.usrModificado);

			//this.categoriaChange(this.pagina.categoria);
		}

		getCategorias(){
			return this.categorias;
		}

		getPaginas(){
			return this.paginas;
		}

		getPagina(){
			return this.pagina;
		}

		categoriaChange(e){

			this.pagina.articulos = {}

			if(typeof e.articulos == 'object'){

				for(var art in e.articulos){
					if(e.articulos[art].tipo == 1){
						this.pagina.articulos[e.articulos[art].codigo] = new ArtHtml();
					}else if(e.articulos[art].tipo == 2){
						this.pagina.articulos[e.articulos[art].codigo] = new ArtImg();

					}else if(e.articulos[art].tipo == 3){
						this.pagina.articulos[e.articulos[art].codigo] = new ArtSlider();

					}else if(e.articulos[art].tipo == 4){
						this.pagina.articulos[e.articulos[art].codigo] = new ArtGaleria();

					}else if(e.articulos[art].tipo == 5){
						this.pagina.articulos[e.articulos[art].codigo] = new ArtCarrera();

					}else if(e.articulos[art].tipo == 6){
						this.pagina.articulos[e.articulos[art].codigo] = new ArtForm();
					}

					this.pagina.articulos[e.articulos[art].codigo].setCategoriaArticulo(e.articulos[art]);
				}
			}
		}

		padreChange(e){
			this.pagina.padre = e;
		}

		enviar(){
			this.ajax.post('pagina/editar',this.pagina).subscribe(
				res => {
					var resp:any = res;

					if(resp.tipo=='confirmo' && resp.resp.resp=="true"){

						this.editado();
					}else if(resp.tipo=='error'){
						var errs = resp.resp.error.errores;
						this.repoErr(errs);
					}
				},
				error => console.error('Error: '),() => console.log(''));

			}

			editado(){
				this.dialogHTML = "<p>La página a sido cargada correctamente.</p>";
				this.openDialog();
				this.router.navigate(["/panel/paginas"]);
			}

			borrar(){

				if(this.pagina.id!=''){
						if(confirm("¿Realmente decea borrar la página '"+this.pagina.titulo+"'?")){
					this.ajax.get('pagina/borrar/'+this.pagina.id).subscribe(
						res => {
							var resp:any = res;

							if(resp.resp=="true"){
								this.router.navigate(["/panel/paginas"]);

							}else if(resp.tipo=='error'){
								var errs = resp.resp.error.errores;
								this.repoErr(errs);
							}
						},
						error => console.error('Error: '),() => console.log(''));
					}
				}
			}

			repoErr(e){
				this.dialogHTML = '';
				for(var i in e){
					this.dialogHTML += "<p>"+e[i].descripcion+"</p>";
				}
				this.openDialog();
			}

			openDialog() {
				this.dialog.open(this.dialogId);
			}

			closeDialog() {
				this.dialog.close(this.dialogId);
			}
		}
