import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { BodyServ } from '../body/body.service';
import { Header } from '../header/header.class';
import { Ajax } from '../services/ajax.service';

declare let gtag: Function;

@Component({
  selector: 'pg-gracias',
  templateUrl:'gracias.component.html'
})


export class GraciasComp implements OnInit{
  public section:any = false;
  public isMadryn = false;
  public isAny = false;
  constructor(
		private route: ActivatedRoute,
		private router: Router,
		public ajax : Ajax,
    public bodyServ : BodyServ
  ){}

  ngOnInit() {

    var h = new Header("Home",false,'1');
    this.bodyServ.setHeader(h);
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);

    // gtag('event', 'conversion', {'send_to': 'AW-995718721/unjUCNKJ6pABEMHs5doD'});




    // gtag('config', 'UA-50921413-1', {'page_path':'gracias'});

    this.route.params.subscribe(params=> {
      if(typeof params["carUrl"] != "undefined"){

        var url = params["carUrl"];

        if(this.router.url=='/gracias/madryn/'+url){
          this.isMadryn = true;
          url = '/madryn/carreras/'+url
        }else{
          this.isMadryn = false;
          url = '/carreras/'+url;
        }

        this.getPag(url);
      }else{
        this.isAny = true;
      }
    });

  }

  getImagen(img){
    var strImg = 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(img.imagen.nombre);
    return strImg;
  }

  getPag(carUrl){
    this.ajax.get('pagina/get/'+carUrl)
    .subscribe(
      res => {

        var resp:any = res;

        if(resp.tipo=='confirmo'){
          var sectAux = resp.resp.data;

          var articulos = {};

          for(var i in sectAux.articulos){
            articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
          }
          this.section = sectAux;
          this.section.articulos = articulos;
        }else{
					this.bodyServ.is404 = true;
        }
      },
      error => console.error('Error: '),() => console.log('Hubo un error'));
  }
}
