import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
/*
* Raise the value exponentially
* Takes an exponent argument that defaults to 1.
* Usage:
*   value | exponentialStrength:exponent
* Example:
*   {{ 2 | exponentialStrength:10 }}
*   formats to: 1024
*/


@Pipe({name: 'getLink'})

export class GetLink implements PipeTransform {

	transform(value: any): string {
		return this.getLinkPadre(value);
	}

	getLinkPadre(pag){

		if(pag.padre != '' ){

			if(pag.padre.id != 0){
				return this.getLinkPadre(pag.padre)+"/"+pag.url;
			}
		}

		return "/"+pag.url;
	}
}

@Pipe({name: 'formatXPadre'})

export class FormatXPadre implements PipeTransform {

	transform(value: any): number {
		return this.transformCont(value);
	}

	transformCont(pag){

		if(pag.padre != '' ){

			if(pag.padre.id != 0){
				var i = this.transformCont(pag.padre);
				return i++;
			}
		}

		return 0;
	}
}

@Pipe({name: 'strLength'})

export class StrLength implements PipeTransform {
	transform(str:string, length: number){
		if(str){

			if(str.length>length){
				return str.substring(0, length)+'...';
			}else{
				return str;
			}
		}
	}
}

@Pipe({name: 'paginar'})

export class Paginar implements PipeTransform {
	transform(arr:Array<any>,ini:number, length:number){

		console.log(ini);
		console.log(length);

		return arr.slice(ini,ini+length);
	}
}

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}


/*

@Pipe({name: 'recursiveToArray'})

export class RecursiveToArray implements PipeTransform {

	transform(menus: any): any[] {

		var arrMenu = [];

		if(menus){
			for(var i in menus){

				arrMenu.push(menus[i]);

				if(menus[i].hijos){
					var arr = this.transform(menus[i].hijos);

					if(arr){
						arrMenu.concat(arr);
					}
				}
			}
		}

		console.log(arrMenu);

		return arrMenu;
	}

}
*/
