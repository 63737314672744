import { Component,Injectable } from '@angular/core';
import { DialogServ } from './dialog.service';

@Injectable()

export class GaleriaModalServ {

	public galeria;
	public imgAct;

	constructor(
		public dialog : DialogServ
		){}

	siguiente(){
		var id = this.galeria.imgs.indexOf(this.imgAct);

		if(id == this.galeria.imgs.length-1){
			id = 0;
		}else{
			id++;
		}

		this.imgAct = this.galeria.imgs[id];

	}

	anterior(){
		var id = this.galeria.imgs.indexOf(this.imgAct);

		if(id == 0){
			id = this.galeria.imgs.length-1;
		}else{
			id--;
		}

		this.imgAct = this.galeria.imgs[id];

	}

	updateImgAct(img){

		var imgFin;

		for(var i in this.galeria.imgs){
			if(this.galeria.imgs[i].imagen.id == img.id){
				imgFin = this.galeria.imgs[i];
				break;
			}
		}


		var id = this.galeria.imgs.indexOf(imgFin);

		this.imgAct = this.galeria.imgs[id];
	}

	open(){
		this.dialog.open('galeria-modal');
	}

	close() {
		this.dialog.close('galeria-modal');
	}
}
