import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PanelComp } from './panel.component';
import { PanelFormularioComp } from './panel-formulario/panel-formulario.component';
import { PanelMultimediaComp } from './panel-multimedia/panel-multimedia.component';

import { MenuCrearComp } from './modelos/menu/menu-crear/menu-crear.component';
import { MenuEditarComp } from './modelos/menu/menu-editar/menu-editar.component';
import { MenuListComp } from './modelos/menu/menu-list/menu-list.component';

import { MensajeListComp } from './modelos/mensaje/mensaje-list/mensaje-list.component';

import { LoginComp } from './modelos/login/login.component';
import { PaginaComp } from './modelos/pagina/pagina.component';
import { PaginaEditarComp } from './modelos/pagina/pagina-editar/pagina-editar.component';
import { PaginaListComp } from './modelos/pagina-list/pagina-list.component';

// import { ImgUploadComp } from "../res/img-upload/img-upload.component"

const routes: Routes = [
  {
    path: '',
    component: PanelComp
  },
  { path: 'login', component: LoginComp},
  // { path: 'img-upload', component: ImgUploadComp},
  // { path: 'galeria', component: GaleriaComp},
  { path: 'formularios', component: PanelFormularioComp},
  { path: 'multimedia', component: PanelMultimediaComp},
  { path: 'paginas', component: PaginaListComp},
  { path: 'paginas/crear', component: PaginaComp},
  { path: 'paginas/editar/:id', component: PaginaEditarComp},
  { path: 'menus', component: MenuListComp},
  { path: 'menus/crear', component: MenuCrearComp},
  { path: 'menus/editar/:id', component: MenuEditarComp},
  { path: 'mensaje', component: MensajeListComp},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PanelRoutingModule { }
