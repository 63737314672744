import { Injectable } from '@angular/core';
import { Ajax } from './ajax.service';

@Injectable({
  providedIn: 'root'
})

export class TgService {

  public tiposdocs : any[];
  public paymentmethods : any[];
  public conceptos : any[];

  constructor(
    public ajax: Ajax
  ) { }

  getTipodoc(){
    this.ajax.get('tg/tiposdocs')
    .subscribe(
      res => {
        var resp:any = res;
        this.tiposdocs = resp.resp;
        console.log(resp);
      },
      error => console.error('Error: '),() => console.log(''));
    }

    getConceptos(){
      this.ajax.get('tg/pagosConceptos')
      .subscribe(
        res => {
          var resp:any = res;
          this.conceptos = resp.resp;
          console.log(resp);
        },
        error => console.error('Error: '),() => console.log(''));
      }

    getPaymentMethods(){
      this.ajax.get('tg/paymentmethods')
      .subscribe(
        res => {
          var resp:any = res;
          if(resp.tipo == 'confirmo'){
            this.paymentmethods = resp.resp;
          }
          // console.log(resp);
        },
        error => console.error('Error: '),() => console.log(''));
      }


    }
