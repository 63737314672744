import { Component,Injectable } from '@angular/core';


@Injectable()

export class DialogServ {

	private dialogs:any = {};

	constructor() { }

	add(dialog){
		this.dialogs[dialog.id] = dialog;
	}

	open(id){
		this.dialogs[id].open();
	}
	
	close(id){
		this.dialogs[id].close();
	}

	toggle(id){
		this.dialogs[id].toggle();
	}
}
