import { Component, Input } from '@angular/core';

import { Ajax } from '../../../../services/ajax.service';


@Component({
	selector: 'c-pagina-iterator',
	templateUrl:'pagina-iterator.component.html'
})

export class PaginaIteratorComp{

	@Input() paginas : any = false;


	constructor(
		public ajax : Ajax
		){}

	getUrl(pag){
		var url = ''
		if(pag.padre.id != 0){
			url = this.getUrl(pag.padre);
		}

		url += '/'+pag.url;

		return url;
	}

	borrar(pag){

		if(confirm("¿Realmente decea borrar la página '"+pag.titulo+"'?")){

			this.ajax.get('pagina/borrar/'+pag.id)
			.subscribe(
				res => {
					var resp:any = res;
					console.log(resp);

					if(resp.resp == 'true'){
						for(var i in this.paginas){
							if(this.paginas[i].id == pag.id){
								this.paginas[i] = false;
							}
						}
					}
				},

				error => console.error('Error: '),() => console.log(''));
		}
	}
}
