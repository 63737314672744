export class Menu{

	constructor(
		public id:any = '',
		public nombre:any = '',
		public link:any = '',
		public padre:any = '',
		public hijos:any = '',
		public orden:any = '',
		public habilitado:any = '',
		public activo:boolean=false,
		public fechModificado:any = '',
		public usrModificado:any = ''
	){
		this.id =id;
		this.nombre =nombre;
		this.link =link;
		this.padre =padre;
		this.hijos =hijos;
		this.orden =orden;
		this.habilitado =habilitado;
		this.activo = activo;
		this.fechModificado =fechModificado;
		this.usrModificado =usrModificado;	
	}
}
