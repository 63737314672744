import { Component, OnInit } from '@angular/core';
import { BodyServ } from '../../body/body.service';
import { PagoService } from '../../services/pago.service';
import { Observable } from 'rxjs';

// import { FormGroup,FormControl,FormBuilder,Validators } from '@angular/forms';
// import { TgService } from '../../services/tg.service';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css'],
  providers:[
    PagoService
  ]
})

export class PagosComponent implements OnInit {

  alumno : any;
  tarjeta : any;
  pago : any;
  errores : any;

  constructor(
    public bodyServ : BodyServ,
    public pagoServ : PagoService
  )
  { }

  ngOnInit() {
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);
    this.bodyServ.header.cod=1;

    this.pagoServ.getAlumno$().subscribe(alumno => {
      // console.log(alumno);
      this.alumno = alumno;
    });

    this.pagoServ.getTarjeta$().subscribe(tarjeta => {
      // console.log(tarjeta);
      this.tarjeta = tarjeta;
    });

    this.pagoServ.getPago$().subscribe(pago => {
      // console.log(pago);
      this.pago = pago;
    });

    this.pagoServ.getErrores$().subscribe(errores => {
      // console.log(errores);

      this.errores = errores;
    });
  }

  getAlumno(){
    // console.log(this.alumno);
    return this.alumno;
  }

  getTarjeta(){
    return this.tarjeta;
  }

  getPago(){
    return this.pago;
  }

  getShowPago(){
    if(this.pago){
      if(typeof this.pago.status != "undefined" && this.pago.status !== null){
        if(this.pago.status == 'approved'){
          return false;
        }
      }
    }

    if(this.alumno && this.tarjeta){
      return true;
    }

    return false;
  }

  getShowPagoFinalizado(){
    if(this.errores){
      return true;
    }
    if(this.pago){
      if(typeof this.pago.status != "undefined" && this.pago.status !== null){
        if(this.pago.status == 'approved' || this.pago.status == 'rejected'){
          return true;
        }
      }
    }
  }

  guardarDatosPersonales(alumno){
    // this.pagoServ.alumno = this.datosPersonalesForm.value;
    this.pagoServ.crearAlumno(alumno);
    // this.sendData(this.datosPersonalesForm.value);
    // console.log(this.datosPersonalesForm.value);
  }

  guardarDatosTarjeta(tarjeta){
    // this.pagoServ.tarjeta = this.datosTarjetaForm.value;
    this.pagoServ.sendDatosTarjeta(tarjeta);
  }

  guardarDatosPago(pago){
    // this.pagoServ.tarjeta = this.datosTarjetaForm.value;
    var textConfirm = 'Usted va a abonar AR$'+pago.amount+' sobre la matrícula '+this.pagoServ.alumno.matricula;
    
    if(confirm(textConfirm)){
      this.pagoServ.sendDatosPago(pago);
    }
  }

  borrarDatosAlumno(){
    this.pagoServ.deletePago();
    this.pagoServ.deleteTarjeta();
    this.pagoServ.deleteAlumno();
  }

  borrarDatosTarjeta(){
    this.pagoServ.deletePago();
    this.pagoServ.deleteTarjeta();
  }
  borrarDatosPago(){
    this.pagoServ.deletePago();
  }
  testAlumno(){
    console.log(this.pagoServ.alumno);
  }

}
