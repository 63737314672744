import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { BodyServ } from '../../body/body.service';
import { Header } from '../../header/header.class';
import { UsuarioService } from '../../services/usuario.service';
import { DialogServ } from '../../services/dialog.service';

import { Ajax } from '../../services/ajax.service';

@Component({
	selector: 'c-panle-formulario',
	templateUrl:'panel-formulario.component.html'
})

export class PanelFormularioComp implements OnInit {

  public forms:any;
	public formSelected:any;
	public dialogId = 'formularioDownloadDialog';
	public desdeFech;
	public formToDownload:any;

	constructor(
		private router:Router,
		public ajax : Ajax,
		public bodyServ : BodyServ,
		public usrServ : UsuarioService,
		public dialogServ : DialogServ
		//,	public plService : PaginaService
		){
	}

	ngOnInit() {
		this.usrServ.isLoged();
    this.iniPanelFormulario();
		var h = new Header("Home",false,'2');
		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
	}

  iniPanelFormulario(){

    this.ajax.get('formulario/listar')
    .subscribe(
      res => {
        var resp:any = res;
        if(resp.tipo=='confirmo'){
          this.forms = resp.resp;
        }
      },
      error => console.error('Error: '));
  }

	openDialog(f) {
		this.setFormSelected(f);
		this.dialogServ.open(this.dialogId);
	}

	closeDialog() {
		this.dialogServ.close(this.dialogId);
	}

	setFecha(fech){
		console.log(fech);
		this.desdeFech = fech;
	}

	setFormSelected(f){
		this.formSelected = f;
	}

	donwloadForm(){

	}

}
