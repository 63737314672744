import { Component, OnInit, Input } from '@angular/core';
import { Ajax } from '../../../../services/ajax.service';

@Component({
	selector: 'pag-art-form',
	templateUrl:'pag-art-form.component.html'
})

export class PagArtFormComp implements OnInit {

	@Input() artPag:any = false;
	public htmlEditor = false;

	public forms:any = false;
	// public opcionesChecked:any = {};

	constructor(
		private ajax:Ajax
		){}

	ngOnInit() {

		this.ajax.get('formulario/listar')
		.subscribe(
			res => {
				var resp:any = res;
				if(resp.tipo=='confirmo'){
					this.forms = resp.resp;
				}
			},
			error => console.error('Error: '));
	}

	isOk(){
		return this.forms && this.artPag;
	}


}
