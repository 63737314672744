import { Component, OnInit, Input } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';
import { ArtImgComp } from '../../../art-img/art-img.component';
import { Slider } from '../../../slider/slider-test/slider.class';
import { Colums } from '../../../res/colums.class';
import { GaleriaModalComp } from '../../../res/galeria/galeria-modal/galeria-modal.component';
import {Router} from "@angular/router";

@Component({
	selector: 'cat-post-form',
	templateUrl:'cat-post-form.component.html'
})

export class CatPostFormComp implements OnInit {

	public formSubmitedStatus = false;

	@Input() categoria:any = false;
	section : any = false;

	constructor(
		public bodyServ : BodyServ,
			public router : Router,
		public ajax : Ajax
		){}

	ngOnInit(){
		this.section = this.bodyServ.getSection();
	}

	getImagen(art){
		return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
	}

	formSubmited(e){
			if(e){
				this.router.navigate(['gracias']);
				//this.formSubmitedStatus = true;
			}
	}
}
