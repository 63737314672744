import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BodyServ } from '../body/body.service';
import { Header } from '../header/header.class';
import { UsuarioService } from '../services/usuario.service';

@Component({
	selector: 'c-panel',
	templateUrl:'panel.component.html'
})

export class PanelComp{

	constructor(
		public bodyServ : BodyServ,
		public usrServ : UsuarioService
		){}

	ngOnInit() {
		this.usrServ.isLoged();
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
		this.bodyServ.header.cod=2;
	}
}
