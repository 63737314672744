import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComp } from './pagina-templates/home/home.component';
import { CarrerasComp } from './pagina-templates/carreras/carreras.component';
import { GraciasComp } from './gracias/gracias.component';
import { NovedadesComp } from './novedades/novedades.component';
import { PasantiasEventosComp } from './pasantias-eventos/pasantias-eventos.component';
import { LandingComp } from './landing/landing/landing.component';
import { LandingMadrynComp } from './landing/landing-madryn/landing-madryn.component';

import { InscCharlasComp } from './landing/insc-charlas/insc-charlas.component';
import { CalendarComp } from './landing/calendar/calendar.component';
import { TestGComp } from './landing/testG/testg.component';
import { InscCharlasFinanzasComp } from './landing/inscCharlaFinanzas/charlaFinanzas.component';

import { SectionHomeComp } from './section-home/section-home.component';
import { TestComp } from './test/test.component';

const routes: Routes = [
  {
    path: 'panel',
    loadChildren: () => import('./panel/panel.module').then(mod => mod.PanelModule)
  },
  {
    path: 'pagos',
    loadChildren: () => import('./pagos/pagos.module').then( m => m.PagosModule)
  },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComp},
  { path: 'test', component: TestComp},
  { path: 'paginas/:id', component: TestComp },
  { path: 'institucional/novedades', component: NovedadesComp},
  { path: 'carreras/organizacion-de-eventos/pasantias-de-la-carrera', component: PasantiasEventosComp},
  { path: 'ingresantes/solicitar-informacion', component: LandingComp},

  { path: 'ingresantes/charlas-informacion', component: InscCharlasComp},
  { path: 'alumnos/calendario-academico', component: CalendarComp},
  { path: 'ingresantes/testg', component: TestGComp},
  { path: 'carreras/finanzas/novedades-de-la-carrera/revolucion-criptomonedas', component: InscCharlasFinanzasComp},
  { path: 'alumnos', redirectTo: 'ingresantes/solicitar-informacion', pathMatch: 'full' },
  { path: 'siteindex.php', redirectTo: 'madryn/carreras', pathMatch: 'full' },
  { path: 'sede-puerto-madryn', redirectTo: 'madryn/carreras', pathMatch: 'full' },
  { path: 'institucional/novedades/solicitar-informacion', redirectTo: 'ingresantes/solicitar-informacion', pathMatch: 'full' },
  { path: 'institucional/novedades/empleos-y-pasantias', redirectTo: 'alumnos/empleos-y-pasantias', pathMatch: 'full' },
  { path: 'institucional/novedades/equivalencias', redirectTo: 'ingresantes/equivalencias', pathMatch: 'full' },

  { path: 'institucional/novedades/solicitar-informacion-diciembre-2022', redirectTo: 'ingresantes/solicitar-informacion', pathMatch: 'full' },

  { path: 'institucional/novedades/articulacion-con-licenciatura', redirectTo: 'ingresantes/preguntas-frecuentes', pathMatch: 'full' },

  { path: 'madryn/solicitar-informacion', component: LandingMadrynComp},
  { path: 'gracias', component: GraciasComp},
  { path: 'gracias/madryn/:carUrl', component: GraciasComp},
  { path: 'gracias/:carUrl', component: GraciasComp},
  { path: ':url1', component: SectionHomeComp },
  { path: ':url1/:url2', component: SectionHomeComp },
  { path: ':url1/:url2/:url3', component: SectionHomeComp },
  { path: ':url1/:url2/:url3/:url4', component: SectionHomeComp }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
}) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
