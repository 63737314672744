import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Ajax } from '../services/ajax.service';


@Component({
  selector: 'c-test',
  templateUrl:'test.component.html',
  styleUrls:['./test.component.css']
})

export class TestComp implements OnInit {


  constructor(
		public ajax: Ajax
  ) {


    }
    ngOnInit() {
    }
  }
