import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { BodyServ } from '../body/body.service';
import { Header } from '../header/header.class';
import { Ajax } from '../services/ajax.service';

@Component({
  selector: 'pg-err-404',
  templateUrl:'err-404.component.html'
})

export class Err404Comp implements OnInit{
  public section:any = false;
  constructor(
		private route: ActivatedRoute,
		private router: Router,
		public ajax : Ajax,
    public bodyServ : BodyServ
  ){}

  ngOnInit() {
    var h = new Header("Home",false,'1');
    this.bodyServ.setHeader(h);
    this.bodyServ.setIsPanel(false);
    this.bodyServ.setFooter(true);
  }
}
