import { Component, OnInit, Input } from '@angular/core';

import { BodyServ} from '../body/body.service';

@Component({
	selector: 'c-header',
	templateUrl:'header.component.html'
})

export class HeaderComp{
	// @Input() header:any = false;

	constructor(
		public bodyServ : BodyServ
	){}

	getHeader(){
		return this.bodyServ.getHeader();
	}
}
