import { Component, OnInit, Input } from '@angular/core';
// import { PagArtHtmlComp } from '../pag-art-html/pag-art-html.component';
//
// import { PagArtImgComp } from '../pag-art-img/pag-art-img.component';
//
// import { PagArtCarreraComp } from '../../../pag-art-carrera/pag-art-carrera.component';

@Component({
	selector: 'pag-arts-list',
	templateUrl:'pag-arts-list.component.html'
})

export class PagArtsListComp{
	@Input() pagina:any = false;
	constructor(){}
}
