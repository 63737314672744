import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PagArtHtmlComp } from './pag-art-html/pag-art-html.component';
import { PagArtsListComp } from './pag-arts-list/pag-arts-list.component';

import { BodyServ } from '../../../body/body.service';
import { Header } from '../../../header/header.class';
import { ArtHtml } from '../../../art-html/art-html.class';
import { ArtImg } from '../../../art-img/art-img.class';
import { ArtSlider } from '../../../art-slider/art-slider.class';
import { ArtGaleria } from '../../../art-galeria/art-galeria.class';
import { ArtCarrera } from '../../../art-carrera/art-carrera.class';
import { ArtForm } from '../../../art-form/art-form.class';
import { Pagina } from './pagina.class';
import { DialogServ } from '../../../services/dialog.service';
import { UsuarioService } from '../../../services/usuario.service';


import { Ajax } from '../../../services/ajax.service';
import { PaginaService } from '../../../services/pagina-list.service';


@Component({
	selector: 'c-pagina',
	templateUrl:'pagina.component.html'
})

export class PaginaComp implements OnInit {
	dialogId : any = 'crearpag';
	dialogHTML : string = '';

	categorias:any = false;
	paginas:any = false;

	pagina : Pagina = new Pagina();

	opened : boolean = false;

	//categoriaSel : any = false;

	constructor(
		private router:Router,
		public ajax : Ajax,
		public bodyServ : BodyServ,
		public dialog : DialogServ,
		public usrServ : UsuarioService
		//,	public plService : PaginaService
		){
	}

	ngOnInit() {
		this.usrServ.isLoged();

		this.iniPagina();
		var h = new Header("Home",false,'2');
		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
	}

	iniPagina(){


		this.pagina.habilitado = true;

		this.ajax.get('pagina/nuevo2')
		.subscribe(
			res => {

				var resp:any = res;
				this.categorias = resp.categorias;
				this.paginas = resp.paginas;
				console.log(this.paginas);
				if(this.categorias[0]==undefined){
					this.pagina.categoria = false;
				}else{

					this.pagina.categoria = this.categorias[0];
					this.categoriaChange(this.pagina.categoria);
				}

				this.paginas.unshift(new Pagina(0,'','Sin padre'));
				this.pagina.padre = (this.paginas[0]==undefined?false:this.paginas[0]);

				console.log(this.categorias);
				console.log(this.paginas);

			},
			error => console.error('Error: '),() => console.log(''));
	}

	getCategorias(){
		return this.categorias;
	}

	getPaginas(){
		return this.paginas;
	}
	/*
	getCategoriaSel(){
		return this.categoriaSel;
	}*/

	getPagina(){
		return this.pagina;
	}

	categoriaChange(e){

		this.pagina.articulos = {}

		if(typeof e.articulos == 'object'){

			for(var art in e.articulos){
				console.log(e.articulos[art]);

				if(e.articulos[art].tipo == 1){
					this.pagina.articulos[e.articulos[art].codigo] = new ArtHtml();
				}else if(e.articulos[art].tipo == 2){
					this.pagina.articulos[e.articulos[art].codigo] = new ArtImg();
				}else if(e.articulos[art].tipo == 3){
					this.pagina.articulos[e.articulos[art].codigo] = new ArtSlider();
				}else if(e.articulos[art].tipo == 4){
					this.pagina.articulos[e.articulos[art].codigo] = new ArtGaleria();
				}else if(e.articulos[art].tipo == 5){
					this.pagina.articulos[e.articulos[art].codigo] = new ArtCarrera();
				}else if(e.articulos[art].tipo == 6){
					this.pagina.articulos[e.articulos[art].codigo] = new ArtForm();
				}

				//Formulario

				this.pagina.articulos[e.articulos[art].codigo].setCategoriaArticulo(e.articulos[art]);
			}
		}
	}

	padreChange(e){
		this.pagina.padre = e;
	}

	enviar(){
		this.ajax.post('pagina/crear',this.pagina).subscribe(
			res => {
				var resp:any = res;
				console.log(resp);
				if(resp.tipo=='confirmo' && resp.resp.resp=="true"){
					this.creada();
				}else if(resp.tipo=='error'){
					var errs = resp.resp.error.errores;
					this.repoErr(errs);

				}
			},
			error => console.error('Error: '),() => console.log(''));


	}

	creada(){
		this.dialogHTML = "<p>La página a sido cargada correctamente.</p>"
		this.openDialog();
		this.router.navigate(["/panel/paginas"]);
	}

	repoErr(e){
		this.dialogHTML = '';
		for(var i in e){
			this.dialogHTML += "<p>"+e[i].descripcion+"</p>";
		}

		this.openDialog();
	}

	openDialog() {
		this.dialog.open(this.dialogId);
	}

	closeDialog() {
		this.dialog.close(this.dialogId);
	}
}
