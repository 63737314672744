import { AfterViewInit, Component, ContentChildren, Directive, ElementRef, Input, Output, EventEmitter, AfterContentInit, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ItemComp } from './item/item.component';
import { Item } from './item/item.class';

@Component({
	selector: 'c-slider-test',
	templateUrl:'slider-test.component.html',
	styleUrls: ['./slider-test.component.css']
})

export class SliderTestComp implements AfterContentInit {

	@Input() slider;
	@Input() items;
	@Input() colums;
	@Input() clase:string='';
	@Output() toggled: EventEmitter<boolean> = new EventEmitter();

	// @ContentChildren(ItemComp) items : QueryList<ItemComp>;

	constructor(){}

	ngAfterContentInit() {}

	ngOnInit(){
	}

	getClase(){
		return this.clase;
	}
}

