import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { GaleriaServ } from '../../../services/galeria.service';
import { StrLength } from '../../pipes/pipes';


@Component({
	selector: 'galeria-seleccion-img',
	templateUrl:'galeria-seleccion-img.component.html'
})

export class GaleriaSeleccionImgComp implements OnInit {

	@Input() id:any = false;
	imgSel:any = false;

	@Output() imgSelected = new EventEmitter<any>()

	imgs:any = false;

	constructor(
		public galeriaServ : GaleriaServ
	){}

	ngOnInit() {
		this.galeriaServ.loadImgs();
	}

	selectImg(img){
		this.imgSel = img;
		this.imgSelected.emit(img);
	}

	encodeURI(uri){
		return encodeURI(uri);
	}
}
