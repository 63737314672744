import { Component, Input } from '@angular/core';

@Component({
	selector: 'pag-art-slider',
	templateUrl:'pag-art-slider.component.html'
})

export class PagArtSliderComp{

	constructor(){}
	@Input() artPag:any = false;
	@Input() artCat:any = false;

	test(){
	}

	getSlider(){
		return this.artPag.slider;
	}

	getSliderSel(e){
		this.artPag.slider = e;
	}

/*
	getTest(){
		return this.artPag.imagen;
	}
*/

}
