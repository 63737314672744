import { Component, OnInit, Input } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';
import { ArtImgComp } from '../../../art-img/art-img.component';
import { Slider } from '../../../slider/slider-test/slider.class';
import { Colums } from '../../../res/colums.class';
import { GaleriaModalComp } from '../../../res/galeria/galeria-modal/galeria-modal.component';
import {Router} from "@angular/router";

@Component({
	selector: 'cat-post-form-noimg',
	templateUrl:'cat-post-form-noimg.component.html'
})


export class CatPostFormNoimgComp implements OnInit {

	// public itemsImg = [];
	// public sliderImg;
	// public imgsSlider;


	@Input() categoria:any = false;
	section : any = false;

	public formSubmitedStatus = false;

	constructor(
		public bodyServ : BodyServ,
		public router : Router,
		public ajax : Ajax
		){}

	ngOnInit(){

		this.section = this.bodyServ.getSection();
    //		this.section = this.bodyServ.getSection();
	}

	formSubmited(e){
			if(e){
				this.router.navigate(['gracias']);
				// this.formSubmitedStatus = true;
			}
	}
}
