import { Component } from '@angular/core';

import { Ajax } from '../../services/ajax.service';
import { BodyServ } from '../../body/body.service';
import { MenuService } from '../../services/menu.service';
import { Header } from '../header.class';
import { HeaderMenuIteratorComp } from '../header-menu-iterator/header-menu-iterator.component';

@Component({
	selector: 'c-header-web',
	templateUrl:'header-web.component.html'
})

export class HeaderWebComp{
	header : any = 'false';
	menu: any = false;
	collapse : boolean = false;

	constructor(
		public ajax : Ajax,
		public bodyServ : BodyServ,
		public menuServ : MenuService
	){
		this.ajax.get('menu/listar')
		.subscribe(
			res => {
				var resp:any = res;

				if(resp.tipo == 'confirmo' && resp.resp.resp=="true"){
					this.menu = resp.resp.data;
				}else{
					this.menu = [];
				}

				//this.menu = resp;
			},
			error => console.error('Error: '),() => console.log(''));
	}

	getHeader(){
		this.header = this.bodyServ.getHeader();
		return this.header;
	}

	toggleCollapseMenu(){

		this.collapse = !this.collapse;

	}
}
