import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DialogComp } from '../dialog/dialog.component';
import { DialogServ } from '../../services/dialog.service';
import { GaleriaServ } from '../../services/galeria.service';
import { Ajax } from '../../services/ajax.service';
import { StrLength } from '../pipes/pipes';


@Component({
	selector: 'galeria-load',
	templateUrl:'galeria-load.component.html'
})

export class GaleriaLoadComp implements OnInit {

	@Input() id:any = false;
	imgSel:any = false;

	@Output() imgSelected = new EventEmitter<any>()

	imgs:any = false;

	constructor(
		public galeriaServ : GaleriaServ,
		public dialogServ : DialogServ,
		public ajax : Ajax
	){}

	ngOnInit() {
		this.galeriaServ.loadImgs();
	}

	openDialog() {
		this.dialogServ.open(this.id);
	}

	closeDialog() {
		this.dialogServ.close(this.id);
	}

	selectImg(img){
		this.imgSel = img;
		this.imgSelected.emit(img);
	}

	encodeURI(uri){
		return encodeURI(uri);
	}
}
