export class Pagina{

	constructor(
		public id : any = '',
		public url : any = '',
		public titulo : any = '',
		public categoria : any = '',
		public padre : any = '',
		public hijos : any = '',
		public articulos : any = '',
		public etiquetas : any = '',
		public habilitado : any = '',
		public fechModificado : any = '',
		public usrModificado : any = '',
	){	
		this.id = id;
		this.url = url;
		this.titulo = titulo;
		this.categoria = categoria;
		this.padre = padre;
		this.hijos = hijos;
		this.articulos = articulos;
		this.etiquetas = etiquetas;
		this.habilitado = habilitado;
		this.fechModificado = fechModificado;
		this.usrModificado = usrModificado;
	}

}













