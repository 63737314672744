import { Component, Input } from '@angular/core';
import { MenuService } from '../../services/menu.service';

@Component({
	selector: 'c-header-menu-iterator',
	templateUrl:'header-menu-iterator.component.html',
	styleUrls:['./header-menu-iterator.component.css']
})

export class HeaderMenuIteratorComp{
	activo:boolean = false;

	@Input() menus : any = false;

	constructor(
		public menuServ : MenuService
	){


	}


	/*open(e){
		this.activo = true;
	}

	close(e){
		this.activo = false;
	}*/

}
