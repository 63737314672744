import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'galeria',
	templateUrl:'galeria.component.html'
})

export class GaleriaComp{

	@Input() galeriaSelect;
	@Input() selectActive;
	@Output() getGaleriaSelect = new EventEmitter<any>();

	constructor(){}

	getImagen(img){

		var strImg = 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(img.imagen.nombre);
		return strImg;
	}

	changeGaleria(g){
		this.selectActive = false;
		this.galeriaSelect = g;
		this.getGaleriaSelect.emit(g);
	}
}
