import { Component, OnInit } from '@angular/core';

import { BodyServ } from '../../../../body/body.service';
import { Header } from '../../../../header/header.class';
import { UsuarioService } from '../../../../services/usuario.service';
import { Ajax } from '../../../../services/ajax.service';


@Component({
	selector: 'mensaje-list',
	templateUrl:'mensaje-list.component.html'
})

export class MensajeListComp implements OnInit {

	public mensajes:any;

	constructor(
		public ajax : Ajax,
		public bodyServ : BodyServ,
		public usrServ : UsuarioService
		){
	}

	ngOnInit() {
		this.usrServ.isLoged();

		this.ajax.get('mensaje/listar').subscribe(
			res => {

				var resp:any = res;


				if(resp.resp.resp == "true"){

					this.mensajes = resp.resp.data;
				}

			},
			error => console.error('Error: '),
			() => console.log(''));


		var h = new Header("Home",false,'2');
		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);
	}

}
