import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'c-pagina-select',
	templateUrl:'pagina-select.component.html'
})

export class PaginaSelectComp implements OnInit{

	@Input() paginas : any = false;
	@Input() pagSelectedIn : any = false;
  @Output() pagSelected = new EventEmitter();
	opened : boolean = false;


	constructor(){}

	ngOnInit() {
		// console.log(this.pagSelectedIn);
		// this.pagSelectedIn = pag;
	}

	pagSelect(pag){
		this.opened = false;
    this.pagSelectedIn = pag;
		this.pagSelected.emit(pag);
	}


}
