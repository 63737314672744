import { Component,Injectable } from '@angular/core';
import { Ajax } from './ajax.service';
import { Usuario } from '../usuario/usuario.class';
import {Router} from "@angular/router";

@Injectable()

export class UsuarioService{

	public usr:Usuario = new Usuario();
	public token:any = false;
	public loged = false;


	constructor(
		public router:Router,
		public ajax: Ajax
		) { }

	login(){

		this.ajax.post('usuario/login',this.usr).subscribe(
			res => {

				var resp:any = res;
				if(resp.resp){
					this.token = resp.token;
					this.usr = resp.usuario;
					this.loged = true;

					localStorage.setItem('data-token', this.token);
					localStorage.setItem('data-usr', JSON.stringify(this.usr));

					this.router.navigate(['panel']);
				}else{
					this.loged = false;
				}
			},
			error => console.error('Error: '));
	}

	isLoged(){

		//Chequear que la sesion continue

		//Se comprueba por localStorage y el servicio

		var token:any = false;
		var usr : any = false;
		var t = localStorage.getItem('data-token');
		var u = JSON.parse(localStorage.getItem('data-usr'));

		if(typeof t != undefined && t != null){

			token = t;
			usr = u;

		}else{
			if(this.token){
				token = this.token;
				usr = this.usr;
			}
		}

		if(token){
			this.ajax.get('usuario/check/'+token).subscribe(
				res => {

					var resp:any = res;

					if(resp.resp){

						//logeado


						this.token = token;
						this.usr = usr;
						this.loged = true;

					}else{

						//No logeado

						this.loged = false;

						localStorage.removeItem('data-token');
						localStorage.removeItem('data-usr');

						if(this.router.url != '/panel/login'){

							this.router.navigate(['/panel/login']);

						}
					}
				},
				error => console.error('Error: '),() => console.log(''));
		}else{

			//No logeado

			this.loged = false;

			if(this.router.url != '/panel/login'){

				this.router.navigate(['/panel/login']);

			}
		}
	}

	logout(){
		localStorage.removeItem('data-token');
		localStorage.removeItem('data-usr');
		this.usr = new Usuario();
		this.token = false;
		this.loged = false;
	}
}
