import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
    selector: 'fm-hidden',
    template: `
      <div [formGroup]="form">
        <input [attr.type]="field.tipo.cod" [id]="field.cod" [name]="field.cod" [formControlName]="field.cod">
      </div>
    `
})

export class HiddenComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

    constructor() {

    }
}
