import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pag-art-galeria',
	templateUrl:'pag-art-galeria.component.html'
})

export class PagArtGaleriaComp{

	@Input() artPag:any = false;

	constructor(){}

	getArtPag(){
		return this.artPag;
	}

	galeriaChange(g){
		this.artPag.galeria = g;
	}
}
