import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
    <ng-container [formGroup]="form" [ngSwitch]="field.tipo.cod">
      <textbox *ngSwitchCase="'text'" [field]="field" [form]="form"></textbox>
      <fm-textarea *ngSwitchCase="'textarea'" [field]="field" [form]="form"></fm-textarea>
      <fm-carrera *ngSwitchCase="'carrera'" [field]="field" [form]="form"></fm-carrera>
      <fm-carrera-madryn *ngSwitchCase="'carrera-madryn'" [field]="field" [form]="form"></fm-carrera-madryn>
      <fm-hidden *ngSwitchCase="'hidden'" [field]="field" [form]="form"></fm-hidden>
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>
      <dropdown *ngSwitchCase="'select'" [field]="field" [form]="form"></dropdown>

      <div class="err d-block" *ngIf="!isValid && isDirty"><p>Necesitamos que ingreses {{field.etiqueta}}</p></div>
    </ng-container>
  `
})

export class FieldBuilderComponent implements OnInit {
  @Input() field:any;
  @Input() form:any;

  get isValid() {
    return this.form.controls[this.field.cod].valid;
  }
  get isDirty() {
    return this.form.controls[this.field.cod].dirty;
  }

  constructor() { }

  ngOnInit() {
  }

}
