import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector:'calendario',
	templateUrl: './calendario.component.html'
})

export class CalendarioComponent{

	@Output() fechChange = new EventEmitter();
	@Input() fechSelected = new Date();

	showFech:boolean = false;

	fechas = [];

	constructor (	) {}

	ngOnInit() {

    var dToday = new Date();

		var yToday = dToday.getFullYear();

		for(var i=0;i< 90;i++){
			this.fechas[i] = {
				year:yToday-i,
				months:[]
			}
		}

		this.loadMonths(this.getYear());
		this.loadDays(this.getMonth());

		this.fechChange.emit(this.fechSelected);
		// console.log(this.fechas);
  }

	getYear(){
		for(var i in this.fechas){
			if(this.fechas[i].year == this.fechSelected.getFullYear()){
				return this.fechas[i]
			}
		}
	}

	getMonth(){
		var y = this.getYear();
		for(var j in y.months){
			if(y.months[j].num-1 == this.fechSelected.getMonth()){

				return y.months[j];
			}
		}
	}

	getDay(){
		var m = this.getMonth();
		for(var j in m.days){
			if(m.days[j].day == this.fechSelected.getDate()){
				console.log(m.days[j].day);
				console.log(this.fechSelected.getDate());
				return m.days[j];
			}
		}
	}

	loadMonths(year){

		year.months = [
						{num:1,nombre:"Enero",days:[]},
						{num:2,nombre:"Febrero",days:[]},
						{num:3,nombre:"Marzo",days:[]},
						{num:4,nombre:"Abril",days:[]},
						{num:5,nombre:"Mayo",days:[]},
						{num:6,nombre:"Junio",days:[]},
						{num:7,nombre:"Julio",days:[]},
						{num:8,nombre:"Agosto",days:[]},
						{num:9,nombre:"Septiembre",days:[]},
						{num:10,nombre:"Octubre",days:[]},
						{num:11,nombre:"Noviembre",days:[]},
						{num:12,nombre:"Diciembre",days:[]}
				];

	}

	loadDays(month){

		var cantDays:any = new Date(this.fechSelected.getFullYear(),this.fechSelected.getMonth()+1,0);
		month.days = [];

		cantDays = cantDays.getDate();
		for(var i = 1 ; i<=cantDays ; i++){
			month.days.push({
				day:i
			})
		}
		console.log(month.days);
	}

	setYear(year){
		this.fechSelected.setFullYear(year.year);
		this.loadMonths(year);
		this.loadDays(this.getMonth());
		this.fechChange.emit(this.fechSelected);
	}

	setMonth(month){
		console.log(month);
		this.fechSelected.setMonth(month.num-1);
		this.loadDays(month);
		 this.fechChange.emit(this.fechSelected);
	}

	setDay(day){
		this.fechSelected.setDate(day.day);

		this.fechChange.emit(this.fechSelected);
	}
}
