import { BrowserModule, Meta } from '@angular/platform-browser';

import {APP_BASE_HREF} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { FormsModule,FormGroup,ReactiveFormsModule } from '@angular/forms';

import { DynamicFormBuilderModule } from './dynamic-form-builder/dynamic-form-builder.module';

import { PanelModule } from './panel/panel.module';

import { AppRoutingModule }     from './routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './router.strategy';

import { AppComponent } from './app.component';

import { TestComp } from './test/test.component';

import { HeaderComp } from './header/header.component';
import { HeaderWebComp } from './header/header-web/header-web.component';
import { HeaderPanelUsrComp } from './header/header-panel-usr/header-panel-usr.component';
import { HeaderMenuIteratorComp } from './header/header-menu-iterator/header-menu-iterator.component';

import { FooterComp } from './footer/footer.component';

import { PanelFormularioComp } from './panel/panel-formulario/panel-formulario.component';
import { PanelMultimediaComp } from './panel/panel-multimedia/panel-multimedia.component';

import { HomeComp } from './pagina-templates/home/home.component';
import { MapComp } from './pagina-templates/home/map/map.component';
import { CarrerasComp } from './pagina-templates/carreras/carreras.component';
import { NovedadesComp } from './novedades/novedades.component';
import { PasantiasEventosComp } from './pasantias-eventos/pasantias-eventos.component';

import { GraciasComp } from './gracias/gracias.component';
import { Err404Comp } from './err-404/err-404.component';
import { LandingComp } from './landing/landing/landing.component';
import { LandingMadrynComp } from './landing/landing-madryn/landing-madryn.component';

import { InscCharlasComp } from './landing/insc-charlas/insc-charlas.component';
import { TestGComp } from './landing/testG/testg.component';
import { InscCharlasFinanzasComp } from './landing/inscCharlaFinanzas/charlaFinanzas.component';


// import { DialogComp } from './res/dialog/dialog.component';
// import { GaleriaModalComp } from './res/galeria/galeria-modal/galeria-modal.component';
// import { GaleriaLoadComp } from './res/galeria-load/galeria-load.component';
// import { GaleriaSeleccionImgComp } from './res/galeria-load/galeria-seleccion-img/galeria-seleccion-img.component';
// import { HtmlEditorComp } from './res/html-editor/html-editor.component';
// import { HtmlEditorToolbarComp } from './res/html-editor/html-editor-toolbar/html-editor-toolbar.component';
// import { HtmlEditorDirect } from './res/html-editor/html-editor.directive';
// import { ImgUploadComp } from './res/img-upload/img-upload.component';
// import { SliderLoadComp } from './res/slider-load/slider-load.component';
// import { ImagenModalComp } from './res/imagen/imagen-modal/imagen-modal.component';
// import { FormatXPadre,StrLength,Paginar,SafeHtmlPipe } from './res/pipes/pipes';
import { ResModule } from './res/res.module';
import { PagosModule } from './pagos/pagos.module';

// import { GaleriaComp } from './galeria/galeria.component';
// import { GaleriaListComp } from './galeria/galeria-list/galeria-list.component';
// import { GaleriaEditComp } from './galeria/galeria-edit/galeria-edit.component';
// import { GaleriaNewComp } from './galeria/galeria-new/galeria-new.component';

//import { SliderComp } from './slider/slider.component';
import { SliderTestComp } from './slider/slider-test/slider-test.component';
import { ItemComp } from './slider/slider-test/item/item.component';

import { FormularioComp } from './formulario/formulario.component';

import { ArtHtmlComp } from './art-html/art-html.component';
import { ArtImgComp } from './art-img/art-img.component';
import { ArtSliderComp } from './art-slider/art-slider.component';
import { ArtGaleriaComp } from './art-galeria/art-galeria.component';
import { SectionHomeComp } from './section-home/section-home.component';

import { CategoriaSelectorComp } from "./categoria/categoria-selector/categoria-selector.component";

import { CatCarrerasComp } from "./categoria/categorias/cat-carreras/cat-carreras.component";
import { CatCarreraComp } from "./categoria/categorias/cat-carrera/cat-carrera.component";
import { CatPostComp } from "./categoria/categorias/cat-post/cat-post.component";
import { CatPostFormComp } from "./categoria/categorias/cat-post-form/cat-post-form.component";
import { CatPostFormNoimgComp } from "./categoria/categorias/cat-post-form-noimg/cat-post-form-noimg.component";
import { CatNovedadesListComp } from "./categoria/categorias/cat-novedades-list/cat-novedades-list.component";

import { CatPostHtmlComp } from "./categoria/categorias/cat-post-html/cat-post-html.component";
import { CatMadrynHomeComp } from "./categoria/categorias/cat-madryn-home/cat-madryn-home.component";


import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

@NgModule({
  declarations: [
    AppComponent,
    TestComp,
    HomeComp,
    NovedadesComp,
    PasantiasEventosComp,
    MapComp,
    CarrerasComp,
    GraciasComp,
    Err404Comp,
    LandingComp,
    InscCharlasComp,
    TestGComp,
    InscCharlasFinanzasComp,
    LandingMadrynComp,
    FormularioComp,
    //SliderComp,
    SliderTestComp,
    ItemComp,
    ArtHtmlComp,
    ArtImgComp,
    ArtSliderComp,
    ArtGaleriaComp,
    SectionHomeComp,
    CategoriaSelectorComp,
    CatCarrerasComp,
    CatCarreraComp,
    CatPostComp,
    CatPostFormComp,
    CatPostFormNoimgComp,
    CatPostHtmlComp,
    CatMadrynHomeComp,
    CatNovedadesListComp,
    HeaderComp,
    HeaderWebComp,
    HeaderPanelUsrComp,
    HeaderMenuIteratorComp,
    FooterComp
  ],

  imports: [
    AppRoutingModule,
    //BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule.withServerTransition({ appId: 'ng-universal-demystified' }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DynamicFormBuilderModule,
    PagosModule,
    PanelModule,
    BrowserAnimationsModule,
    ResModule
    //QuillEditorModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    Meta

    //{provide: RouteReuseStrategy,useClass: CacheRouteReuseStrategy}
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
