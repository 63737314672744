import { Component,Injectable } from '@angular/core';


@Injectable()

export class LoadSpinnerServ {

	public on:boolean = false;

	constructor(){}
}
