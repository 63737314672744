import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'c-pagina-search',
	templateUrl:'pagina-search.component.html',
})

export class PaginaSearch{

	@Input() paginas:any = false;
  @Output() searchPags = new EventEmitter();
  query : any = '';

	constructor(){
	}

  search(pags:any = false,sent = true){
		console.log("searching");

    if(pags == false){
      pags = this.paginas;
    }

    var pagsOk = [];

    for(var i in pags){
      if(pags[i].hijos != null){
        var childrensOk = this.search(pags[i].hijos,false);
				console.log(childrensOk);
					if(childrensOk.length>0){
						pags[i].hijos = childrensOk;
						pagsOk.push(pags[i]);
						continue;
					}
      }

      if(pags[i].titulo.toUpperCase().search(this.query.toUpperCase())>=0){
          pagsOk.push(pags[i]);
      }
    }

		if(sent){
			this.searchPags.emit(pagsOk);
		}else{
			return pagsOk;
		}
  }
}
