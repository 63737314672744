import { Component, OnInit } from '@angular/core';


@Component({
	selector: 'lista-desplegable',
	templateUrl:'lista-desplegable.component.html',
	//styleUrls: ['/new/assets/css/panel.css']
})

export class ListaDesplegable implements OnInit {

	public activa:boolean = false;

	constructor(){}

	ngOnInit() {

	}
}
