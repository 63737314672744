import { Component, OnInit } from '@angular/core';
import { BodyServ } from '../../body/body.service';
import { Output, EventEmitter } from '@angular/core';
import { FormGroup,FormControl,FormBuilder,Validators } from '@angular/forms';
import { TgService } from '../../services/tg.service';
import { PagoService } from '../../services/pago.service';

@Component({
  selector: 'datos-tarjeta',
  templateUrl: './datos-tarjeta.component.html',
  styleUrls: ['./datos-tarjeta.component.css']
})
export class DatosTarjetaComponent implements OnInit {

    @Output() sendData = new EventEmitter<any>();

    years : any[];

    months : any[];

    datosTarjetaForm = this.fb.group({
      card_number:['',Validators.required],
      card_expiration_month:['',[
          Validators.required
        ]],
      card_expiration_year:['',[
          Validators.required
        ]],
      security_code:['',Validators.required],
      card_holder_name:['',Validators.required],
      card_holder_identification:this.fb.group({
        type:['dni'],
        number:['',Validators.required]
      }),
      device_unique_identifier:[''],
      concepto:['',Validators.required]
    })



    constructor(
      private fb: FormBuilder,
      public bodyServ : BodyServ,
      public tgServ : TgService,
      public pagoServ : PagoService
    )
    { }

    ngOnInit() {
      console.log(this.pagoServ.alumno);
      // this.tgServ.getTipodoc();
      this.tgServ.getConceptos();

      this.years = this.getYears();
      this.months = this.getMonths();
    }

    getYears(){
      var actYear:any = new Date();
      // actYear = actYear.getFullYear();
      var years = [];
      var y = new Date();

      for(var i=0;i<=10;i++){

        var desc = y.getFullYear().toString();
        var value = desc.substr(-2);

        years.push({
          desc:desc,
          value:value
        });

        y = new Date(actYear.setFullYear(actYear.getFullYear() + 1));
      }
      return years;
    }

    getMonths(){
      var i = 0

      if(this.datosTarjetaForm.get('card_expiration_year').value!=''){
        var m = new Date();
        var isToday = m.getFullYear().toString().substr(-2);
        if(isToday == this.datosTarjetaForm.get('card_expiration_year').value){
          i = m.getMonth() - 1;
        }
      }

      var months = [];
      var actMonth = new Date();

      for(i;i<12;i++){
        actMonth.setMonth(i,1);

        var month = (actMonth.getMonth()+1).toString();
        
        month = (month.length==1?"0"+month:month);

        months.push({desc:month,value:month});
      }

      return months;

    }

    changeYear(){
      this.months = this.getMonths();

      if(this.datosTarjetaForm.get('card_expiration_month').value!=''){
        var m = new Date();
        var exists = false;
        this.months.forEach(m => {
          if(m.value == this.datosTarjetaForm.get('card_expiration_month').value){
            exists = true;
          }
        });

        if(!exists){
          this.datosTarjetaForm.get('card_expiration_month').setValue('');
        }
      }
    }


    guardarDatosTarjeta(){
      // this.pagoServ.tarjeta = this.datosTarjetaForm.value;
      // this.pagoServ.sendDatosTarjeta();
      this.sendData.emit(this.datosTarjetaForm.value);
    }
}
