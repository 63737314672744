import { Component, OnInit, Input, HostListener,ViewChild,ElementRef } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import {Router} from "@angular/router";

import { LoadSpinnerServ } from '../../../services/load-spinner.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';
import { ArtImgComp } from '../../../art-img/art-img.component';


@Component({
	selector: 'cat-carrera',
	templateUrl:'cat-carrera-2.component.html'
})

export class CatCarreraComp implements OnInit {

	@Input() categoria:any = false;

	@ViewChild('laCarrera', {static: false}) laCarrera: ElementRef;
	@ViewChild('planEstudio', {static: false}) planEstudio: ElementRef;
	@ViewChild('campoLaboral', {static: false}) campoLaboral: ElementRef;
	@ViewChild('requisitos', {static: false}) requisitos: ElementRef;
	@ViewChild('novsCar', {static: false}) novsCar: ElementRef;
	@ViewChild('infoExtra', {static: false}) infoExtra: ElementRef;
	@ViewChild('navPills', {static: false}) navPills: ElementRef;

	isSticky:boolean = false;

	section : any = false;

	ops:any = [
	{
		cod:1,
		desc:"LA CARRERA",
		isSticky:false,
		isDisplayed:true,
		el:'laCarrera'
	},{
		cod:2,
		desc:"PLAN DE ESTUDIOS",
		isSticky:false,
		isDisplayed:false,
		el:'planEstudio'
	},{
		cod:3,
		desc:"CAMPO LABORAL",
		isSticky:false,
		isDisplayed:false,
		el:'campoLaboral'
	},{
		cod:4,
		desc:"REQUISITOS",
		isSticky:false,
		isDisplayed:false,
		el:'requisitos'
	},{
		cod:6,
		desc:"NOVEDADES DE LA CARRERA",
		isSticky:false,
		isDisplayed:false,
		el:'novsCar'
	},{
		cod:7,
		desc:"PASANTIAS Y NOVEDADES",
		isSticky:false,
		isDisplayed:false,
		el:'pasYNovs'
	}
	,{
		cod:5,
		desc:"+INFO / ARANCELES",
		isSticky:false,
		isDisplayed:false,
		el:'infoExtra'
	}
];

	opSelected:any = this.ops[0];
	pillsOpen:boolean = false;
	novedadesPag : any = false;
	formDefValues:any = false;
	formSubmitedStatus = false;

	constructor(
		public router : Router,
		public bodyServ : BodyServ,
		public ajax : Ajax,
		public loadSpinnerServ : LoadSpinnerServ
		){

		this.loadSpinnerServ.on = false;
	}

	ngOnInit(){
		this.section = this.bodyServ.getSection();

		this.ajax.get('pagina/hijas/'+this.section.id+'/'+encodeURI('titulo')).subscribe(
			res => {

				var resp:any = res;


				if(resp.resp){
					if(resp.resp.resp == false){
						this.section.hijas = false;
					}else{

						this.section.hijas = resp.resp.data;

						for(var j in this.section.hijas){
							if(this.section.hijas[j].categoria.id == 28){
								this.novedadesPag = this.section.hijas[j];
							}
						}
					}
				}
			},
			error => console.error('Error: '),() => console.log(''));


		this.formDefValues = {
			'car-1':this.section.titulo,
			//'ref':document.referrer
			'link':this.getQueryString(),
			'ref':document.referrer
			 /*GEG*/
		}


/*		let aux = "gaston";
			let varG = this.getParameterByName(aux);
			console.log("REFERENCIA");
			console.log(this.getParameterByName('gaston'));
			console.log(this.getQueryString());
			console.log("REFERENCIA");
*/
	}

	getImagen(art){
		return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
	}

	isOpSelected(op){
		return op.cod==this.opSelected.cod;
	}


	verOp(op){
		if(op.cod == 7 && this.section.id!=86){
			return false;
		}

		if(op.cod==3 && this.section.id==88){
			return false;
		}

		if(op.cod == 4 && (this.novedadesPag!=false || this.section.id==86)){
			return false;
		}

		if(op.cod==6 && this.novedadesPag==false){
			return false;
		}

		return true;
	}

	selectOp(op){
		if(op.cod == 6){
			this.router.navigate(['carreras',this.section.url,'novedades-de-la-carrera']);
			return;
		}else if(op.cod == 7){
			this.router.navigate(['carreras',this.section.url,'pasantias-de-la-carrera']);
			return;
		}

		this.pillsOpen = false;

		for(var i in this.ops){
			if(this.ops[i].cod==op.cod){
				this.ops[i].isDisplayed = true;
			}
		}

		this.opSelected = op;


		var el = this[this.opSelected.el].nativeElement.getBoundingClientRect();

		var offsetTopIni = el.top + window.pageYOffset - 300;

		window.scrollTo({
			top: offsetTopIni,
			left: 0,
			behavior: 'smooth'
		});

	}

	@HostListener("window:scroll", ['$event'])

	changeSticky($event:Event){

		let scrollOffset = (<HTMLInputElement>$event.srcElement).children[0].scrollTop;
		var el;

		el = this.navPills.nativeElement.getBoundingClientRect();

		var offsetTopIni = el.top + window.pageYOffset - scrollOffset;
		var offsetTopFin = el.bottom + window.pageYOffset - scrollOffset;


		if(offsetTopIni<60 && offsetTopFin>60){
			this.isSticky = true;
		}else{
			this.isSticky = false;
		}


	}

	checkCarOp(opNombre){


		if(this.section.articulos.carrera_opciones){

			for(var i in this.section.articulos.carrera_opciones.carrera.opciones){

				if(this.section.articulos.carrera_opciones.carrera.opciones[i].nombre == opNombre){
					return true;
				}
			}
		}

		return false;

	}

	getFormDefValues(){
		var values = {};
		return values;
	}

	formSubmited(e){
		if(e){
			this.router.navigate(['gracias',this.section.url]);
		}
	}

	formSubmitedMadryn(e){
		if(e){
			this.router.navigate(['gracias','madryn',this.section.url]);
		}
	}

	formSubmitedContacto(e){
			if(e){
				this.formSubmitedStatus = true;
				this.router.navigate(['gracias',this.section.url]);
			}
	}

	/*GEG*/
getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
getQueryString() {
		var url_search = window.location.search;
		url_search = url_search.replace('?','');
		return url_search;
}

}
