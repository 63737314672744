export class Item{

	public activo:boolean = false;
	public itemOutLeft:boolean = false;
	public posicion:number = 0;
	public contenido:any = false;

	constructor(activo,posicion,contenido){
		this.activo = activo;
		this.posicion = posicion;
		this.contenido = contenido;
	}

}
