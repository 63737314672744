import { UsuarioService } from '../services/usuario.service';

export class Usuario{

	public nombre:string;
	public pw:string;

	constructor(nombre='',pw=''){
		this.nombre = nombre;
		this.pw = pw;
	}
}
