import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
	selector: 'c-pagina-iterator-select',
	templateUrl:'pagina-iterator-select.component.html'
})

export class PaginaIteratorSelectComp implements OnInit{

	@Input() paginas : any = false;
	@Input() pagSelectedIn : any = false;
  @Output() pagSelected = new EventEmitter();

	constructor(){}

	ngOnInit() {
		// console.log(this.pagSelectedIn);
		// if(this.pagSelectedIn){
		// this.pagSelectedIn = pag;
		//
		// }
	}

	pagSelect(pag){
		this.pagSelectedIn = pag;
		this.pagSelected.emit(pag);
	}
}
