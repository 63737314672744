import { Component, OnInit, Input } from '@angular/core';
import { BodyServ } from '../../../body/body.service';
import { Ajax } from '../../../services/ajax.service';
import { ArtHtmlComp } from '../../../art-html/art-html.component';
import { ArtImgComp } from '../../../art-img/art-img.component';
import { Slider } from '../../../slider/slider-test/slider.class';
import { Colums } from '../../../res/colums.class';
import { GaleriaModalComp } from '../../../res/galeria/galeria-modal/galeria-modal.component';

@Component({
	selector: 'cat-post',
	templateUrl:'cat-post.component.html'
})

export class CatPostComp implements OnInit {

	public itemsImg = [];
	public sliderImg;
	public imgsSlider;


	@Input() categoria:any = false;
	section : any = false;

	constructor(
		public bodyServ : BodyServ,
		public ajax : Ajax
		){}

	ngOnInit(){

		// this.ajax.get('slider/getId/2').subscribe(
		// 	res => {
		//
		// 		var resp:any = res;
		//
		// 		if(resp.resp.resp){
		// 			this.imgsSlider = resp.resp.data.imgs;
		//
		// 			var c = new Colums();
		//
		// 			c.setXsCols(1);
		//
		// 			this.sliderImg = new Slider(c,600,this.imgsSlider,this.itemsImg,6000);
		//
		// 			this.itemsImg = this.sliderImg.contenidosToItems(this.imgsSlider);
		//
		// 			this.sliderImg.iniciar();
		// 		}
		//
		// 	},
		// 	error => console.error('Error: '),
		// 	() => console.log('consulta completa'));

		this.section = this.bodyServ.getSection();
	}

	getImagen(art){
		if(this.section.padre.id==102){
			return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/banner-novedades2.jpg';
		}else{
			return 'https://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
		}
	}
}
