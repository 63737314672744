
/* / <reference path="../../../../node_modules/@types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />*/

import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Ajax } from '../../services/ajax.service';

import { BodyServ } from '../../body/body.service';
import { LoadSpinnerServ } from '../../services/load-spinner.service';
import { Header } from '../../header/header.class';
import { Slider } from '../../slider/slider-test/slider.class';
import { ItemComp } from '../../slider/slider-test/item/item.component';
import { Colums } from '../../res/colums.class';
import {Router} from "@angular/router";

import { trigger,style,transition,animate,state } from "@angular/animations";

@Component({
	selector: 'c-home',
	templateUrl:'home.component.html',
	animations: [
	trigger('entradaEstadisticas',[
		state('inicial',style({
			transform: 'translateX(-100%)',
			opacity:0
		})),
		state('inicialL',style({
			transform: 'translateX(200%)',
			opacity:0
		})),

		state('entra',style({
			transform: 'translateX(0)',
			opacity:1
		})),
		state('entraL',style({
			transform: 'translateX(0)',
			opacity:1
		})),
		transition('inicial => entra',[
			animate(1500)
		]),
		transition('entra => inicial',[
			animate(100)
		]),
		transition('inicialL => entraL',[
			animate(1500)
		]),
		transition('entraL => inicialL',[
			animate(100)
		])

	])
]
})

export class HomeComp implements OnInit,AfterContentInit {

	public formSubmitedStatus = false;

	public sedeAct = 1;

	public myMap;

	public itemsImg = [];
	public sliderImg;
	public sliderImg2;
	public imgsSlider;

	public itemsPag = [];
	public sliderPag;
	public pagsSlider;

	public sliderBan;
	public imgsSliderBan;
	public itemsPagBan = [];

	public loaderOfCount = 0;
	public loaderOfNumber = 2;

	public section;

	public estado = ['inicial','inicialL','inicial','inicialL','inicial'];
	public posScroll = 0;
	public posEst = [];

	constructor(
		public bodyServ : BodyServ,
		public ajax : Ajax,
			public router : Router,
		  public loadSpinnerServ : LoadSpinnerServ
		){

		 this.loadSpinnerServ.on = true;
	}

		formDefValues:any = false;

	ngAfterContentInit(){
	// 	this.myMap = new Microsoft.Maps.Map(document.getElementById('map'), {
	//     center: new Microsoft.Maps.Location(-34.60948, -58.3787),
	//     zoom: 15});
	//
	// 	var center = this.myMap.getCenter();
	// 	var infobox = new Microsoft.Maps.Infobox(center, { title: 'La Fundacion Sede Central',
	// 	    description: 'Encontranos en Hipólito Yrigoyen 874, CABA, Buenos Aires, Argentina' });
	// 	infobox.setMap(this.myMap);
	//
	// 	infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(-42.779018, -65.024279),{
	// 			title: 'La Fundacion Sede Madryn',
	// 	    description: 'Encontranos en Roberts 61, Puerto Madryn, Chubut, Argentina' });
	// 	infobox.setMap(this.myMap);

	}

	ngOnInit() {

		this.bodyServ.setSection(false);

		this.ajax.get('pagina/get/home')
		.subscribe(
			res => {


				var resp:any = res;

				if(resp.tipo=='confirmo'){
					var sectAux = resp.resp.data;

					var articulos = {};

					for(var i in sectAux.articulos){
						articulos[sectAux.articulos[i].categoriaArticulo.codigo] = sectAux.articulos[i];
					}

					this.section = sectAux;
					this.section.articulos = articulos;
					this.bodyServ.setSection(this.section);
				}else{
					this.bodyServ.is404 = true;
				}

			},
			error => console.error('Error: '),() => console.log(''));

		this.ajax.get('slider/getId/3').subscribe(
			res => {

				var resp:any = res;

				if(resp.resp.resp){
					this.imgsSlider = resp.resp.data.imgs;

					var c = new Colums();

					c.setXsCols(0);
					c.setSmCols(1);

					this.sliderImg = new Slider(c,300,this.imgsSlider,this.itemsImg,6000);

					this.itemsImg = this.sliderImg.contenidosToItems(this.imgsSlider);

					this.sliderImg.iniciar();

					/**GEG2021**/
					this.sliderImg2 = new Slider(c,100,this.imgsSlider,this.itemsImg,6000);
					this.itemsImg = this.sliderImg2.contenidosToItems(this.imgsSlider);
					this.sliderImg2.iniciar();
					/**GEG2021**/
				}
				this.setOfLoader();

			},
			error => console.error('Error: '),
			() => console.log(''));
/******TEST INI********/
this.ajax.get('slider/getId/3').subscribe(
	res => {

		var resp:any = res;

		if(resp.resp.resp){
			this.imgsSliderBan = resp.resp.data.imgs;


			var c = new Colums();

			c.setXsCols(1);
			c.setSmCols(1);

			//this.sliderBan = new Slider(c,400,this.pagsSlider,this.itemsPag,8000);
			this.sliderBan = new Slider(c,400,this.imgsSliderBan,this.itemsPagBan,8000);
			this.itemsPagBan = this.sliderBan.contenidosToItems(this.imgsSliderBan);

			this.sliderBan.iniciar();

		}

		this.setOfLoader();

	},
	error => console.error('Error: '),
	() => console.log(''));
/******TEST FIN********/

		this.ajax.get('pagina/hijas/102/'+encodeURI('titulo:descripcion_breve:imagen')+'/6').subscribe(
			res => {
				var resp:any = res;
				if(resp.resp.resp){
					this.pagsSlider = resp.resp.data;

					for(var j in resp.resp.data){

						var articulos = {};

						for(var i in resp.resp.data[j].articulos){
							articulos[resp.resp.data[j].articulos[i].categoriaArticulo.codigo] = resp.resp.data[j].articulos[i];
						}

						resp.resp.data[j].articulos = articulos;
					}

					var c = new Colums();

					c.setXsCols(1);
					c.setSmCols(3);

					this.sliderPag = new Slider(c,400,this.pagsSlider,this.itemsPag,8000);

					this.itemsPag = this.sliderPag.contenidosToItems(this.pagsSlider);

					this.sliderPag.iniciar();

				}

				this.setOfLoader();

			},
			error => console.error('Error: '),
			() => console.log(''));

		this.bodyServ.header.titulo = "Home";
		this.bodyServ.header.cod = '1';
		this.bodyServ.isPanel = false;
		this.bodyServ.footer = true;
/***/
					let aux = [];
						for(let i = 1;i<6;i++){
							aux[i] = document.getElementById("Est"+i);
							this.posEst[i]= aux[i].offsetTop;
						}


						this.formDefValues = {
							//'car-1':this.section.titulo,
							//'ref':document.referrer
							'link':this.getQueryString(),
							'ref':document.referrer
							 /*GEG*/
						}

	}
/*
	checkMapLoad(){
		if(typeof Microsoft != "undefined"){
			if(typeof Microsoft.Maps != "undefined"){
				if(typeof Microsoft.Maps.Location != "undefined"){
						return true;
				}
			}
		}
	}

	mapGoBsas(){

		this.sedeAct=1;
		this.myMap.setView({
			center: new Microsoft.Maps.Location(-34.60948, -58.3787),
			zoom:15
		});

	}

	mapGoMadryn(){
		this.sedeAct=2;
		this.myMap.setView({
			center: new Microsoft.Maps.Location(-42.779018, -65.024279),
			zoom:15
		});


	}

*/
	getImagen(art){
		return 'http://lafundacion.edu.ar/new_php_v2/public/upload/img/'+encodeURI(art.imagen.nombre);
	}

	getSedeImg(){
		return (this.sedeAct == 1? 'map_central.png':'map_madryn.png');
	}

	formSubmited(e){
			if(e){
				// this.formSubmitedStatus = true;
				this.router.navigate(['gracias']);
			}
	}

	setOfLoader(){
		 this.loaderOfCount++;
		 if(this.loaderOfCount>= this.loaderOfNumber){
		 	this.loadSpinnerServ.on = false;
		 }
	}

	getScroll(){
	let scrollTop = document.documentElement.scrollTop;


	this.posScroll =  scrollTop;
	/*geg1*/
	//let pant = window.outerHeight;
	let pant = window.screen.height - 10;

					let aux = [];
					for(let i = 1;i<6;i++){
						aux[i] = document.getElementById("Est"+i);
						this.posEst[i]= aux[i].offsetTop;
						this.estado[1] = (this.posEst[1] - pant )>= this.posScroll  ? 'inicial' : 'entra';
						this.estado[2] = (this.posEst[2] - pant )>= this.posScroll  ? 'inicialL' : 'entraL';
						this.estado[3] = (this.posEst[3] - pant )>= this.posScroll  ? 'inicial' : 'entra';
						this.estado[4] = (this.posEst[4] - pant )>= this.posScroll  ? 'inicialL' : 'entraL';
						this.estado[5] = (this.posEst[5] - pant )>= this.posScroll  ? 'inicial' : 'entra';

						if((this.posEst[1] - pant )>= this.posScroll){
							console.log( "Ok" );
							//this.estado = (this.posEst[1] - pant )>= this.posScroll  ? 'inicial' : 'entra';
						}
						console.log( this.estado[1] );
						//this.estado = this.posEst[i] < this.posScroll  ? 'inicial' : 'entra';
					}
}

getFormDefValues(){
	var values = {};
	return values;
}

getQueryString() {
		var url_search = window.location.search;
		url_search = url_search.replace('?','');
		return url_search;
}
}
