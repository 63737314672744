import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Ajax } from '../services/ajax.service';

declare let gtag: Function;

@Component({
  selector: 'c-formulario',
  templateUrl:'formulario.component.html'
})

export class FormularioComp  implements OnInit{

  public form:FormGroup;
  public formStruct;
  public isSubmited;

  @Input() formId : any;
  @Input() pagina : any;

  public _defValues:any = false;

  @Output() onSubmit = new EventEmitter();

  @Input()
  set defValues(defValues:any) {
    this._defValues = defValues;
    this.defValuesToFields();
  }


  unsubcribe: any;


  public fields: any[] = [];

  constructor(
    public ajax: Ajax
  ) { }

  ngOnInit(){
    this.ajax.get('formulario/get/'+this.formId)
    .subscribe(
      res => {

        var resp:any = res;

        resp = resp.resp;
        this.formStruct = resp;

        this.fields = resp.items;
        this.defValuesToFields();
        this.form = new FormGroup({
          fields: new FormControl(JSON.stringify(this.fields))
        })

        this.unsubcribe = this.form.valueChanges.subscribe((update) => {

          this.fields = JSON.parse(update.fields);
        });

      },
      error => console.error('Error: '),() => console.log(''));
    }

    onUpload(e) {
      // console.log(e);
    }

    getFields() {
      return this.fields;
    }

    defValuesToFields(){

      if(this._defValues){
        for(var i in this.fields){
          if(typeof this._defValues[this.fields[i].cod] != 'undefined'){
            this.fields[i].value = this._defValues[this.fields[i].cod];
          }
        }
      }
    }

    ngDistroy() {
      this.unsubcribe();
    }

    submitForm(e){

      // gtag('config', 'UA-50921413-1', {'page_path':'test-p'});
      // gtag('event', 'conversion', {'send_to': 'AW-995718721/unjUCNKJ6pABEMHs5doD'});

      this.isSubmited = true;

      var f = {
        id:this.formStruct.id,
        pagina:this.pagina,
        respItems:e
      }

      this.ajax.post('formulario/respNueva',f).subscribe(
        res => {
          var resp:any = res;

          if(resp.tipo=='confirmo' && resp.resp.resp=="true"){
            // console.log("Todo ok");
            // console.log(resp);
            this.onSubmit.emit(true);

          }else if(resp.tipo=='error'){
            this.onSubmit.emit(false);
            // var errs = resp.resp.error.errores;
          }

          this.isSubmited = false;
        },
        error => console.error('Error: '),() => console.log(''));
      }

    }
