import { Component,Input } from '@angular/core';

@Component({
	selector: 'categoria-selector',
	templateUrl:'categoria-selector.component.html'
})

export class CategoriaSelectorComp {
	@Input() c:any = false;

	constructor(){
		// console.log(this.c);
	}
}
