import { Injectable } from '@angular/core';
import { Ajax } from './ajax.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagoService {

  base : string = 'https://live.decidir.com/api/v2';
  public alumno : any;
  public tarjeta : any;
  public pago : any;

  public errores : any;

  public alumno$ = new Subject<any>();
  public tarjeta$ = new Subject<any>();
  public pago$ = new Subject<any>();
  public errores$ = new Subject<any>();

  constructor(
    public ajax: Ajax,
    public http: HttpClient
  ) { }

  getAlumno$(): Observable<any> {
    return this.alumno$.asObservable();
  }

  getTarjeta$(): Observable<any> {
    return this.tarjeta$.asObservable();
  }

  getPago$(): Observable<any> {
    return this.pago$.asObservable();
  }

  getErrores$(): Observable<any> {
    return this.errores$.asObservable();
  }

  setAlumno(alumno){
    this.alumno = alumno;
    this.alumno$.next(this.alumno);
  }

  setTarjeta(tarjeta){
    this.tarjeta = tarjeta;
    this.tarjeta$.next(this.tarjeta);
  }

  setPago(pago){
    this.pago = pago;
    this.pago$.next(this.pago);
  }

  setErrores(errores){
    this.errores = errores;
    this.errores$.next(this.errores);
  }

  deleteAlumno(){
    this.alumno = false;
    this.alumno$.next(this.alumno);
  }

  deleteTarjeta(){
    this.tarjeta = false;
    this.tarjeta$.next(this.tarjeta);
  }

  deletePago(){
    this.pago = false;
    this.pago$.next(this.pago);
  }

  deleteErrores(){
    this.errores = false;
    this.errores$.next(this.errores);
  }

  crearAlumno(alumno){
    this.deleteErrores();

    if(alumno){

      this.ajax.post('alumno/crear',alumno).subscribe(
        res => {
          var resp:any = res;
          if(resp.tipo == 'confirmo'){
            this.setAlumno(resp.resp);
          }else{

            this.setErrores(resp.resp.errores);
          }
        },
        error => {
          // console.log("error");
        });
      }
    }

    crearPago(){
      this.deleteErrores();

      if(this.pago && this.alumno){

        this.ajax.post('pago/crear',
        {
          alumno:this.alumno,
          pago:this.pago
        }).subscribe(res => {
          var resp:any = res;
          if(resp.tipo == 'confirmo'){
            this.setPago(resp.resp);
          }else{
            this.setErrores(resp.resp.errores);
          }
        }, error => console.error('Error: '),() => console.log(''));
      }
    }

    actualizarPago(){
      this.deleteErrores();

      if(this.pago && this.alumno){

        this.ajax.post('pago/actualizar',
        {
          pago:this.pago
        }).subscribe(res => {
          var resp:any = res;
          if(resp.tipo == 'confirmo'){
            this.setPago(resp.resp);
          }else{
            this.setErrores(resp.resp.errores);
          }
        },error => console.error('Error: '),() => console.log(''));
      }
    }

    sendDatosTarjeta(tarjeta){
      this.deleteErrores();
      if(tarjeta){

        const httpOptions = {
          headers: new HttpHeaders({
            // 'apikey':'e9cdb99fff374b5f91da4480c8dca741',
            'apikey':'a56837e0328640efb1572352633d8e02',
            'Content-Type':'application/json',
            'Cache-Control':'no-cache'
          })
        };

        this.setTarjeta(tarjeta);

        tarjeta.card_number = tarjeta.card_number.toString();
        tarjeta.security_code = tarjeta.security_code.toString();
        tarjeta.card_holder_identification.number = tarjeta.card_holder_identification.number.toString();

        var binAux = tarjeta.card_number.substring(0, 6);

        this.http.post(
          this.base+"/tokens",tarjeta,httpOptions
        ).subscribe(res => {
          var resp : any = res;

          this.pago = {
            name: resp.cardholder.name,
            type: resp.cardholder.identification.type,
            number: resp.cardholder.identification.number,
            token: resp.id,
            // bin: resp.bin,
            bin: binAux,
            status: resp.status,
            concepto: tarjeta.concepto
          }

          this.crearPago();

        },e =>{
          var errs = [];

            if(typeof e.error["message"] == "undefined"){

              for(var i in e.error.validation_errors){
                errs.push({
                  "codigo":e.error.error_type,
                  "descripcion":"Hubo un error en la transacción. Error: "+e.error.validation_errors[i].code+" - "+e.error.validation_errors[i].param+"."
                });
              }

            }else{
              errs.push({
                "codigo":"message",
                "descripcion":"Hubo un error en la transacción. Error: "+e.error.message+"."
              });
            }
          this.setErrores(errs);
        });

      }else{
        return false;
      }
    }

    sendDatosPago(pago){
      this.deleteErrores();

      if(pago){

        var amount = pago.amount
        var p = {
          "site_transaction_id":"FAECC"+this.pago.id,
          "token": this.pago.token,
          "payment_method_id": pago.paymentmethod.id,
          "bin": this.pago.bin,
          "amount": Math.trunc(pago.amount*pago.installments.interes*100),
          "currency": "ARS",
          "installments": pago.installments.cuotas,
          "description": "",
          "payment_type": "single",
          "sub_payments": []
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'apikey':'2b341ea88cea4beea9200f39e74f7303',
            'Content-Type':'application/json',
            'Cache-Control':'no-cache'
          })
        };

        this.http.post(
          this.base+"/payments",p,httpOptions
        ).subscribe(res => {
          var resp : any = res;
          // this.pago = {}

          var resp : any = res;

          this.pago["id"] = this.pago.id;
          this.pago["paymentmethod"] = resp.payment_method_id;
          this.pago["amount"] = resp.amount/100;
          this.pago["installments"] = resp.installments;
          this.pago["status"] = resp.status;
          this.pago["statusDetails"] = resp.status_details;

          this.actualizarPago();

        }, e =>{
          var errs = [];


          if(e.status == "402"){
            // console.log(e);
            var resp = e.error;
            this.pago["id"] = this.pago.id;
            this.pago["paymentmethod"] = resp.payment_method_id;
            this.pago["amount"] = resp.amount/100;
            this.pago["installments"] = e.installments;
            this.pago["status"] = resp.status;
            this.pago["statusDetails"] = resp.status_details;

            this.actualizarPago();
          }else{

            if(typeof e.error["message"] == "undefined"){

              for(var i in e.error.validation_errors){
                errs.push({
                  "codigo":e.error.error_type,
                  "descripcion":"Hubo un error en la transacción. Error: "+e.error.validation_errors[i].code+" - "+e.error.validation_errors[i].param+"."
                });
              }

            }else{
              errs.push({
                "codigo":"message",
                "descripcion":"Hubo un error en la transacción. Error: "+e.error.message+"."
              });
            }
            this.setErrores(errs);
          }
        });

      }else{
        return false;
      }
    }

  }
