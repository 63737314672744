//import { Articulo } from '../articulo/articulo.interface';

export class ArtHtml{// implements Articulo{
	id;
	idArt;
	pagina;
	descripcion;
	categoriaArticulo;
	fechModificado;
	usrModificado;

	constructor(
		id=false,
		idArt=false,
		pagina=false,
		descripcion=false,
		categoriaArticulo=false,
		fechModificado=false,
		usrModificado=false
		){
		this.id = id;
		this.idArt = idArt;
		this.pagina = pagina;
		this.categoriaArticulo = categoriaArticulo;
		this.fechModificado = fechModificado;
		this.usrModificado = usrModificado;
		this.descripcion = descripcion;
	}

	setCategoriaArticulo(categoriaArticulo){
		this.categoriaArticulo = categoriaArticulo;
	}
}