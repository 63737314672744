
export class Header{
	constructor(
		public titulo : any = false,
		public menu : any = false,
		public cod : string = '0'
	){	
		this.titulo = titulo;
		this.menu = menu;
		this.cod = cod;

	}
}