import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { DialogComp } from './dialog/dialog.component';
import { Breadcrumb } from './breadcrumb/breadcrumb.component';
import { BreadcrumbIterator } from './breadcrumb/breadcrumb-iterator/breadcrumb-iterator.component';
import { ListaDesplegable } from './lista-desplegable/lista-desplegable.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { GaleriaModalComp } from './galeria/galeria-modal/galeria-modal.component';
import { GaleriaLoadComp } from './galeria-load/galeria-load.component';
import { GaleriaSeleccionImgComp } from './galeria-load/galeria-seleccion-img/galeria-seleccion-img.component';
import { HtmlEditorComp } from './html-editor/html-editor.component';
import { HtmlEditorToolbarComp } from './html-editor/html-editor-toolbar/html-editor-toolbar.component';
import { HtmlEditorDirect } from './html-editor/html-editor.directive';
import { ImgUploadComp } from './img-upload/img-upload.component';
import { SliderLoadComp } from './slider-load/slider-load.component';
import { ImagenModalComp } from './imagen/imagen-modal/imagen-modal.component';
import { GetLink,FormatXPadre,StrLength,Paginar,SafeHtmlPipe } from './pipes/pipes';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule
  ],
  exports:[
    DialogComp,
    Breadcrumb,
    BreadcrumbIterator,
    ListaDesplegable,
    CalendarioComponent,
    GaleriaModalComp,
    GaleriaLoadComp,
    GaleriaSeleccionImgComp,
    HtmlEditorComp,
    HtmlEditorToolbarComp,
    HtmlEditorDirect,
    ImgUploadComp,
    SliderLoadComp,
    ImagenModalComp,
    FormatXPadre,
    GetLink,
    StrLength,
    Paginar,
    SafeHtmlPipe
  ],
  declarations:[
    //Aca van todos los componentes que quiero importar en otros modulos.
    DialogComp,
    Breadcrumb,
    BreadcrumbIterator,
    ListaDesplegable,
    CalendarioComponent,
    GaleriaModalComp,
    GaleriaLoadComp,
    GaleriaSeleccionImgComp,
    HtmlEditorComp,
    HtmlEditorToolbarComp,
    HtmlEditorDirect,
    ImgUploadComp,
    SliderLoadComp,
    ImagenModalComp,
    FormatXPadre,
    GetLink,
    StrLength,
    Paginar,
    SafeHtmlPipe
  ]
})
export class ResModule { }
