import { Component, OnInit } from '@angular/core';

import { PagArtHtmlComp } from '../pagina/pag-art-html/pag-art-html.component';
import { PagArtsListComp } from '../pagina/pag-arts-list/pag-arts-list.component';

import { BodyServ } from '../../../body/body.service';
import { UsuarioService } from '../../../services/usuario.service';
import { Header } from '../../../header/header.class';
import { ArtHtml } from '../../../art-html/art-html.class';
import { ArtImg } from '../../../art-img/art-img.class';
import { Pagina } from '../pagina/pagina.class';
import { DialogServ } from '../../../services/dialog.service';

import { Ajax } from '../../../services/ajax.service';


@Component({
	selector: 'c-pagina-list',
	templateUrl:'pagina-list.component.html',
	//styleUrls: ['/new/assets/css/panel.css']
})

export class PaginaListComp implements OnInit {

	paginas:any = [];
	paginasSearch:any = [];

	constructor(
		public ajax : Ajax,
		public dialog : DialogServ,
		public bodyServ : BodyServ,
		public usrServ : UsuarioService
		){
	}

	ngOnInit() {
		this.usrServ.isLoged();

		this.iniPagina();
		var h = new Header("Home",false,'2');
		this.bodyServ.setHeader(h);
		this.bodyServ.setIsPanel(true);
		this.bodyServ.setFooter(false);

	}

	iniPagina(){
		this.ajax.get('pagina/listar')
		.subscribe(
			res => {
				var resp:any = res;
				this.paginas = resp;

				for(var i in this.paginas){
					this.setPadres(this.paginas[i]);
				}
			},
			error => console.error('Error: '),() => console.log(''));
	}

	getPaginas(){
		if(this.paginasSearch.length > 0){
			return this.paginasSearch;
		}
		
		return this.paginas;
	}

	setPadres(pag){
		if(typeof pag.hijos == 'object'){
			for(var i in pag.hijos){
				pag.hijos[i].padre = pag;
				this.setPadres(pag.hijos[i]);
			}
		}
	}
}
