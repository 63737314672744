import { Component, OnInit, Input } from '@angular/core';
import { Ajax } from '../../../../services/ajax.service';

@Component({
	selector: 'pag-art-carrera',
	templateUrl:'pag-art-carrera.component.html'
})

export class PagArtCarreraComp implements OnInit {

	@Input() artPag:any = false;

	public opciones:any = false;
	public opcionesChecked:any = {};

	constructor(
		private ajax:Ajax
		){}



	ngOnInit() {

		this.ajax.get('opcion/get/')
		.subscribe(
			res => {
				var resp:any = res;


				for(var i in resp.resp.resp){
					this.opcionesChecked[resp.resp.resp[i].id] = this.isChecked(resp.resp.resp[i]);
				}

				this.opciones = resp.resp.resp;
			},
			error => console.error('Error: '));
	}

	itsOk(){

		if(this.artPag && this.opciones){

			if(this.artPag.carrera){
				if(this.artPag.carrera.opciones){
					return true;
				}
			}
		}

		return false;
	}

	changeOp(op){
		//Si la opcion quedo chekeada tengo que asegurarme de que este en el registro del articulo, si no sacarla


		if(this.opcionesChecked[op.id]){
			this.setOp(op);
		}else{
			this.removeOp(op);
		}

	}

	isChecked(op){

		if(typeof this.artPag != 'undefined'){
			if(this.artPag.carrera.opciones){
				for(var i in this.artPag.carrera.opciones){
					if(this.artPag.carrera.opciones[i].id == op.id){
						return true;
					}
				}
			}
		}

		return false;
	}

	removeOp(op){

		for(var i in this.artPag.carrera.opciones){

			if(this.artPag.carrera.opciones[i].id == op.id){

				this.artPag.carrera.opciones.splice(i,1);
			}
		}

	}

	setOp(op){

		for(var i in this.artPag.carrera.opciones){

			if(this.artPag.carrera.opciones[i].id == op.id){
				return true;
			}
		}

		this.artPag.carrera.opciones.push(op);
	}

}
