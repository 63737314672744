import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

@Component({
    selector: 'dynamic-form-builder',
    templateUrl:'dynamic-form-builder.component.html'
})

export class DynamicFormBuilderComponent implements OnInit {
    @Output() onSubmit = new EventEmitter();
    @Input() isSubmited;
    @Input() fields: any = [];


    public form: FormGroup;

    constructor() { }

    ngOnInit() {
        let fieldsCtrls = {};

        for (let f of this.fields) {

            if (f.tipo.cod != 'checkbox') {
                if(f.tipo.cod == 'select'){
                  var d:any = false;
                  for(let o of f.opciones){
                    if(o.default == 1){
                      d = o.cod;
                    }
                  }
                  fieldsCtrls[f.cod] = new FormControl(d || '');
                }else{

                if(f.cod=='nombre' || f.cod=='apellido' || f.cod=='tel' || f.cod=='email' || f.cod=='carreras'){
                  fieldsCtrls[f.cod] = new FormControl(f.value || '', Validators.required);
                }else{
                  fieldsCtrls[f.cod] = new FormControl(f.value || '');
                }
              }

            }else{
                let opts = {};
                if(f.opciones){

                  for (let opt of f.opciones) {
                    opts[opt.cod] = new FormControl(false);

                  }

                  fieldsCtrls[f.cod] = new FormGroup(opts);

                }
            }
        }
        this.form = new FormGroup(fieldsCtrls);
    }

    test(asd){
      // console.log(asd);
    }
}
