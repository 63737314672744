import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,FormBuilder, Validators } from '@angular/forms';
import { TgService } from '../../services/tg.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'datos-pago',
  templateUrl: './datos-pago.component.html',
  styleUrls: ['./datos-pago.component.css']
})
export class DatosPagoComponent implements OnInit {

  @Output() sendData = new EventEmitter<any>();

  precioCuota = 0.00
  precioTotal = 0.00

  datosPagoForm = this.fb.group({
    paymentmethod: ['1',Validators.required],
    amount:['0.00',Validators.required],
    installments:['1',Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    public tgServ : TgService
  ) { }

  ngOnInit() {
    this.tgServ.getPaymentMethods();
  }

  calcularCuota(){
    // $total=($monto*$interes);
    // $cuota=($monto*$interes)/$cuotas;
    var pago = this.datosPagoForm.value;
    var interes = 0;

    if(this.tgServ.paymentmethods){
      for(var i in this.tgServ.paymentmethods){
        if(this.tgServ.paymentmethods[i].id == pago.paymentmethod){
          pago.paymentmethod = this.tgServ.paymentmethods[i];
          break;
        }
      }

      if(typeof pago.paymentmethod == 'object' && pago.paymentmethod !== null){
        for(var j in pago.paymentmethod.intereses){
          if(pago.paymentmethod.intereses[j].cuotas == pago.installments){
            pago.installments = pago.paymentmethod.intereses[j];
          }
        }
      }

      this.precioCuota = (pago.amount*pago.installments.interes)/pago.installments.cuotas;
      this.precioTotal = (pago.amount*pago.installments.interes);
    }

  }

  guardarDatosPago(){

    var pago = this.datosPagoForm.value;
    var interes = 0;

    if(this.tgServ.paymentmethods){
      for(var i in this.tgServ.paymentmethods){
        if(this.tgServ.paymentmethods[i].id == pago.paymentmethod){
          pago.paymentmethod = this.tgServ.paymentmethods[i];
          break;
        }
      }

      if(typeof pago.paymentmethod == 'object' && pago.paymentmethod !== null){
        for(var j in pago.paymentmethod.intereses){
          if(pago.paymentmethod.intereses[j].cuotas == pago.installments){
            pago.installments = pago.paymentmethod.intereses[j];
          }
        }
      }

      this.sendData.emit(this.datosPagoForm.value);
    }
  }
}
